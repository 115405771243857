import React, { useState, useEffect } from 'react';
import WorkerList from '../../components/WorkerList/WorkerList';
import {getUsers, addUser, updateUser, deleteUser, softdeleteUser, getInactiveUsers} from '../../services/userService';
import EatTimeButton from "../../components/common/EatTimeButton";
import ProjectList from '../../components/ProjectList/ProjectList';
import { getAvailableProjectsPaginated } from '../../services/projectService';
import ProjectDetailView from "../../components/ProjectList/ProjectDetailView";
import SettingsModal from "../../components/common/SettingsModal";

const WorkerSelectionView = ({ onSelectWorker, role, viewType, setViewType, project, setProject, workers, setWorkers }) => {
    const [showingInactiveUsers, setShowingInactiveUsers] = useState(false);

    const [projects, setProjects] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize] = useState(20);
    const [totalItems, setTotalItems] = useState(0);

    //set to current year by default
    const [ejercicio, setEjercicio] = useState(new Date().getFullYear());
    const [serie, setSerie] = useState('');
    const [numero, setNumero] = useState('');

    //settings modal
    const [settingsModal, setSettingsModal] = useState(false);

    //const currentTime = new Date();
    //const currentHour = currentTime.getHours();
    const showElement = true //currentHour >= 13 && currentHour < 14;

    const addNewUser = async (user) => {
        try {
            const newUser = await addUser(user);
            setWorkers(workers => [...workers, newUser]);
        } catch (error) {
            console.error('Error adding user:', error);
        }
    };

    const editUser = async (id, user) => {
        try {
            const updatedUser = await updateUser(id, user);

            let updatedWorkers = workers.map(worker => worker.UserID === id ? updatedUser : worker);

            if(!showingInactiveUsers) {
                updatedWorkers = updatedWorkers.filter(worker => worker.IsActive === true);
            }
            else {
                updatedWorkers = updatedWorkers.filter(worker => worker.IsActive === false);
            }

            setWorkers(updatedWorkers);
        } catch (error) {
            console.error('Error updating user:', error);
        }
    };

    const delUser = async (id) => {
        try {
            await deleteUser(id);
            setWorkers(workers.filter(worker => worker.UserID !== id));
        } catch (error) {
            console.error('Error deleting user:', error);
        }
    };

    const softdelUser = async (id) => {
        try {
            await softdeleteUser(id);
            setWorkers(workers.filter(worker => worker.UserID !== id));
        } catch (error) {
            console.error('Error deleting user:', error);
        }
    };

    const fetchUsers = async () => {
        try {
            const users = await getUsers();
            setWorkers(users);
        } catch (error) {
            console.error('Error fetching users:', error);
        }
    };

    const toggleInactiveUsers = async () => {
        if(!showingInactiveUsers) {
            const users = await getInactiveUsers();
            setWorkers(users);
            setShowingInactiveUsers(true);
        }
        else {
            const users = await getUsers();
            setWorkers(users);
            setShowingInactiveUsers(false);
        }
    }

    const fetchProjects = async (page, ejercicio, serie, numero) => {
        try {
            const response = await getAvailableProjectsPaginated(page, pageSize, ejercicio, serie, numero);
            setProjects(response.projects);
            if (response.totalProjects !== undefined) {
                setTotalItems(response.totalProjects);
            }
        } catch (error) {
            // Handle error
        }
    };

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const onSelectProject = (project) => {
        setProject(project);
    };

    // Fetch data on initial render, on viewType change and when currentPage changes.
    useEffect(() => {
        if (viewType === 'projects') {
            fetchProjects(currentPage, ejercicio, serie, numero);
        }
        else {
            fetchUsers();
        }
    }, [viewType, currentPage, ejercicio, serie, numero]);

    return (
        <div>
            {project && viewType === 'projects' && role && (role.toLowerCase() === "admin" || role.toLowerCase() === "superadmin") ? (
                <ProjectDetailView project={project} />
            ) : viewType === 'projects' && role && (role.toLowerCase() === "admin" || role.toLowerCase() === "superadmin") ? (
                <ProjectList
                    projects={projects}
                    onSelectProject={onSelectProject}
                    currentPage={currentPage}
                    totalItems={totalItems}
                    pageSize={pageSize}
                    onPageChange={handlePageChange}
                    ejercicio={ejercicio}
                    serie={serie}
                    numero={numero}
                    onEjercicioChange={setEjercicio}
                    onSerieChange={setSerie}
                    onNumeroChange={setNumero}
                />
            ) : (
                <WorkerList workers={workers} onSelectWorker={onSelectWorker} onAddUser={addNewUser} onEditUser={editUser} onDeleteUser={delUser} onSoftdeleteUser={softdelUser} onToggleInactiveUsers={toggleInactiveUsers} showingInactiveUsers={showingInactiveUsers} role={role} />
            )}
            {viewType === 'workers' && role && (role.toLowerCase() === "admin" || role.toLowerCase() === "superadmin") && (
                <>
                    <h2 className="font-medium text-2xl mt-10">Otras acciones</h2>
                    <div className="switch-button flex flex-col gap-3 mt-4">
                        <button
                            className="bg-blue-800 hover:bg-blue-900 text-white font-bold py-2 px-4 rounded"
                            onClick={() => setViewType(viewType === 'workers' ? 'projects' : 'workers')}
                        >
                            {viewType === 'workers' ? 'Mostrar Albaranes' : 'Mostrar Trabajadores'}
                        </button>
                        <button
                            className="bg-blue-800 hover:bg-blue-900 text-white font-bold py-2 px-4 rounded"
                            onClick={() => setSettingsModal(true)}
                            >
                            Ver ajustes
                        </button>
                    </div>
                    {settingsModal && role && role.toLowerCase() === "superadmin" && (
                        <SettingsModal isOpen={settingsModal} setIsOpen={setSettingsModal} />
                    )}
                    { /* (<EatTimeButton></EatTimeButton>)*/ }
                </>
            )}
        </div>
    );
};

export default WorkerSelectionView;