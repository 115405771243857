import React, {useEffect, useState} from 'react';
import {getProjectDetails, startTaskAdmin} from '../../services/projectService';
import {formatDate, formatDate1, formatElapsedTimeHM, getButtonColor} from "../../services/utils";
import {removeTask, updateTask} from "../../services/taskService";
import {toast} from "react-toastify";
import EditTaskModal from "../TaskHistory/EditTaskModal";
import AddTaskModal from "./AddTaskModal";
import {getTasksByAlbaran} from "../../services/tasks2Service";
import {getTasksByTimeEntryID, postSubTimeEntry} from "../../services/subTimeEntriesService";
import AddTimeEntryModal from "../common/AddTimeEntryModal";

const ProjectDetailView = ({project}) => {
    const [projectDetails, setProjectDetails] = useState([]);

    const [selectedTask, setSelectedTask] = useState(null);
    const [projectTasks, setProjectTasks] = useState([]);
    const [subTimeEntries, setSubTimeEntries] = useState(new Map());

    const [taskTimes, setTaskTimes] = useState(new Map()); // Map UserID to its duration
    const [totalProjectTime, setTotalProjectTime] = useState(0); // Total project time in milliseconds

    const [isAddTaskModalOpen, setIsAddTaskModalOpen] = useState(false);
    const [isAddTimeEntryModalOpen, setIsAddTimeEntryModalOpen] = useState(false);

    useEffect(() => {
        fetchProjectDetails();
    }, [project]);

    const calculateDuration = (startTime, endTime) => {
        // Assuming startTime and endTime are Date objects
        return endTime - startTime; // This gives duration in milliseconds
    };

    const calculateTotalTime = (timeEntriesMap) => {
        let total = 0;
        for (let duration of timeEntriesMap.values()) {
            total += duration;
        }
        return total;
    };

    const handleAddTimeEntry = async (timeEntry, taskIds) => {
        try {
            timeEntry.CodigoEmpresa = project.CodigoEmpresa;
            timeEntry.EjercicioAlbaran = project.EjercicioAlbaran;
            timeEntry.SerieAlbaran = project.SerieAlbaran;
            timeEntry.NumeroAlbaran = project.NumeroAlbaran;

            const newTimeEntry = await startTaskAdmin(timeEntry);

            try {
                for (const taskId of taskIds) {
                    const task = {
                        TimeEntryID: newTimeEntry.TimeEntryID,
                        TaskID: taskId
                    }
                    await postSubTimeEntry(task);
                }
            } catch (error) {
                // for now, do nothing
            }

            // Update taskTimes and totalProjectTime
            const startTime = new Date(newTimeEntry.StartTime);
            const endTime = new Date(newTimeEntry.EndTime);
            const duration = calculateDuration(startTime, endTime);
            const userName = newTimeEntry.User?.FirstName + ' ' + newTimeEntry.User?.LastName;
            if (taskTimes.has(userName)) {
                taskTimes.set(userName, taskTimes.get(userName) + duration);
            } else {
                taskTimes.set(userName, duration);
            }

            setTotalProjectTime(calculateTotalTime(taskTimes));

            // Update projectDetails
            setProjectDetails(prevDetails => ({
                ...prevDetails,
                TimeEntries: [...prevDetails.TimeEntries, newTimeEntry]
            }));

            // Update subTimeEntries (if needed)
            if (taskIds.length > 0) {
                try {
                    const subTimeEntries = await getTasksByTimeEntryID(newTimeEntry.TimeEntryID);
                    setSubTimeEntries(prevSubTimeEntries => new Map(prevSubTimeEntries.set(newTimeEntry.TimeEntryID, subTimeEntries)));
                } catch (error) {
                    // For now, nothing
                    console.log('Error fetching sub time entries:', error);
                }
            }

            toast.success("Tarea añadida correctamente");
        } catch (error) {
            toast.error("Error al añadir tarea: " + error);
        }
    }

    const fetchProjectDetails = async () => {
        try {
            const details = await getProjectDetails(project);
            setProjectDetails(details);
            const tasks = await getTasksByAlbaran(project.CodigoEmpresa, project.EjercicioAlbaran, project.SerieAlbaran, project.NumeroAlbaran);
            setProjectTasks(tasks);
            //save subtimeentries in a map per task
            let subTimeEntriesMap = new Map();
            let taskTimesMap = new Map();
            for (const task of details.TimeEntries) {
                try {
                    if (task.StartTime && task.EndTime) {
                        // Calculate and store task duration
                        const startTime = new Date(task.StartTime);
                        const endTime = new Date(task.EndTime);
                        const duration = calculateDuration(startTime, endTime);
                        const userName = task.User?.FirstName + ' ' + task.User?.LastName;
                        if (taskTimesMap.has(userName)) {
                            taskTimesMap.set(userName, taskTimesMap.get(userName) + duration);
                        } else {
                            taskTimesMap.set(userName, duration);
                        }
                    }

                    const subTimeEntries = await getTasksByTimeEntryID(task.TimeEntryID);
                    subTimeEntriesMap.set(task.TimeEntryID, subTimeEntries);
                } catch (error) {
                    // Handle error
                }
            }
            setSubTimeEntries(subTimeEntriesMap);
            setTaskTimes(taskTimesMap);
            setTotalProjectTime(calculateTotalTime(taskTimesMap));
        } catch (error) {
            // Handle error
        }
    };

    const editTask = async (id, task) => {
        try {
            console.log(JSON.stringify(task))
            const updatedTask = await updateTask(id, task);

            setProjectDetails(prevDetails => ({
                ...prevDetails,
                TimeEntries: prevDetails.TimeEntries?.map(t => t.TimeEntryID === id ? updatedTask : t)
            }));
        } catch (error) {
            console.error('Error updating task:', error);
        }
    }

    const handleEditClick = (task) => {
        setSelectedTask(task);
    };

    const handleRemoveClick = (taskid) => {
        toast(({closeToast}) =>
                <div>
                    <p>¿Seguro que quieres eliminar esta tarea?
                        <button className="ml-7 text-white !bg-green-700"
                                onClick={() => {

                                    removeTask(taskid)
                                        .then(() => {
                                            setProjectDetails(prevDetails => ({
                                                ...prevDetails,
                                                TimeEntries: prevDetails.TimeEntries?.filter(t => t.TimeEntryID !== taskid)
                                            }));
                                            toast.success("Tarea eliminada correctamente");
                                        })
                                        .catch((error) => {
                                            toast.error("Error al eliminar la tarea: " + error);
                                        })
                                        .finally(() => {
                                            closeToast();
                                        })
                                }}
                        >
                            Confirmar
                        </button>
                    </p>
                </div>,
            {type: toast.TYPE.INFO}
        )
    };

    return (
        <div>
            <AddTaskModal
                isAddTaskModalOpen={isAddTaskModalOpen}
                setIsAddTaskModalOpen={setIsAddTaskModalOpen}
                project={project}
                projectTasks={projectTasks}
                setProjectTasks={setProjectTasks}
            />
            <AddTimeEntryModal
                isOpen={isAddTimeEntryModalOpen}
                onClose={() => setIsAddTimeEntryModalOpen(false)}
                onSave={handleAddTimeEntry}
                task={projectDetails}
            />
            <EditTaskModal selectedTask={selectedTask} setSelectedTask={setSelectedTask} onEditTask={editTask}/>
            <div className="flex flex-row gap-3 justify-between items-center">
                <h2 className="text-2xl font-medium">Detalle albarán</h2>
                <div className={`${project.SerieAlbaran !== "OT" ? "hidden" : "flex flex-row gap-2"}`}>
                    <button
                        className="text-2xl sm:text-2xl !bg-blue-800 md:hover:!bg-blue-950 text-white font-bold py-2 px-2.5 rounded max-h-[60px] flex items-center"
                        onClick={() => setIsAddTaskModalOpen(true)}
                    >
                        <svg xmlns="http://www.w3.org/2000/svg"
                             className="h-6 w-6 text-white cursor-pointer"
                             viewBox="0 0 24 24"
                             fill="none"
                        >
                            <path fillRule="evenodd" clipRule="evenodd"
                                  d="M8.56078 20.2501L20.5608 8.25011L15.7501 3.43945L3.75012 15.4395V20.2501H8.56078ZM15.7501 5.56077L18.4395 8.25011L16.5001 10.1895L13.8108 7.50013L15.7501 5.56077ZM12.7501 8.56079L15.4395 11.2501L7.93946 18.7501H5.25012L5.25012 16.0608L12.7501 8.56079Z"
                                  fill="currentColor"/>
                        </svg>
                    </button>
                </div>
            </div>
            <button
                className={`${getButtonColor(projectDetails.SerieAlbaran)} brand text-white mt-6 font-bold !text-lg leading-relaxed py-2 px-4 w-full h-full text-center`}
            >
                {project.SerieAlbaran}-{project.NumeroAlbaran}<br/>
                {project.RazonSocial}<br/>
                {project.Matricula && (
                    <>
                        {project.Matricula}<br/>
                    </>
                )}
                {project.FechaAlbaran && formatDate(project.FechaAlbaran)}
            </button>
            {projectTasks && projectTasks.length > 0 && (
                <div className="mt-8">
                    <h3 className="text-xl font-medium">Tareas seleccionadas</h3>
                    <ul>
                        {projectTasks.map(task => (
                            <li key={task.Name}>{task.Name}</li>
                        ))}
                    </ul>
                </div>
            )}
            <div className="mt-8">
                <h3 className="text-xl font-medium">Resumen de tareas</h3>
                <ul>
                    {taskTimes.size > 0 &&
                        Array.from(taskTimes.entries()).map(([userName, duration]) => (
                            <li key={userName}> {userName}: {formatElapsedTimeHM(0, duration)} </li>
                        ))
                    }
                    <li><strong className="font-medium">Total:</strong> {formatElapsedTimeHM(0, totalProjectTime)}</li>
                </ul>
            </div>
            <div className="flex flex-row items-center gap-3 mt-8">
                <h3 className="text-xl font-medium">Tareas realizadas</h3>
                <button
                    className="text-2xl sm:text-2xl !bg-blue-800 md:hover:!bg-blue-950 text-white font-bold py-1.5 px-1.5 rounded max-h-[60px] flex items-center"
                    onClick={() => setIsAddTimeEntryModalOpen(true)}
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-4 w-4 text-white cursor-pointer rotate-45"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        onClick={() => true}
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={3}
                            d="M6 18L18 6M6 6l12 12"
                        />
                    </svg>
                </button>
            </div>
            {projectDetails ? (
                <div className="grid grid-cols-1 auto-rows-max mt-6 auto-cols-max gap-5 sm:grid-cols-2 md:grid-cols-3">
                    {projectDetails.TimeEntries && projectDetails.TimeEntries.length > 0 ? (
                        projectDetails.TimeEntries.map((task, index) => (
                            <div
                                key={task.TimeEntryID}
                                className="flex flex-col"
                            >
                                <div
                                    className="w-full bg-gray-600 flex justify-end items-center p-1.5 rounded-t rounded-b-none">
                                    <svg xmlns="http://www.w3.org/2000/svg"
                                         className="h-6 w-6 text-white cursor-pointer"
                                         viewBox="0 0 24 24"
                                         fill="none"
                                         onClick={() => handleEditClick(task)}
                                    >
                                        <path fillRule="evenodd" clipRule="evenodd"
                                              d="M8.56078 20.2501L20.5608 8.25011L15.7501 3.43945L3.75012 15.4395V20.2501H8.56078ZM15.7501 5.56077L18.4395 8.25011L16.5001 10.1895L13.8108 7.50013L15.7501 5.56077ZM12.7501 8.56079L15.4395 11.2501L7.93946 18.7501H5.25012L5.25012 16.0608L12.7501 8.56079Z"
                                              fill="currentColor"/>
                                    </svg>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="h-6 w-6 text-white cursor-pointer"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor"
                                        onClick={() => handleRemoveClick(task.TimeEntryID)}
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth={2}
                                            d="M6 18L18 6M6 6l12 12"
                                        />
                                    </svg>
                                </div>
                                <button key={index}
                                        className={`!bg-gray-700 brand text-white font-bold !text-lg leading-relaxed py-2 px-4 w-full h-full text-center rounded-b rounded-t-none -mt-1`}
                                >
                                    {task.User?.FirstName + ' ' + task.User?.LastName}<br/><br/>
                                    {formatDate1(task.StartTime)}
                                    {task.EndTime &&
                                        <>
                                            <br/>
                                            {formatDate1(task.EndTime)}
                                            <br/>
                                            {formatElapsedTimeHM(task.StartTime, task.EndTime)}
                                        </>
                                    }
                                    {!task.EndTime &&
                                        <>
                                            <br/>
                                            {"Tarea Activa"}
                                        </>
                                    }
                                    {task.WorkerComment &&
                                        <>
                                            <br/><br/>
                                            {task.WorkerComment}
                                        </>
                                    }
                                    {subTimeEntries.has(task.TimeEntryID) && <><br/><br/></>}
                                    {subTimeEntries.get(task.TimeEntryID) && subTimeEntries.get(task.TimeEntryID).map((subTimeEntry) => (
                                        <div key={subTimeEntry.TimeEntryID + subTimeEntry.TaskID}>
                                            {subTimeEntry.Task?.Name}<br/>
                                        </div>
                                    ))}
                                </button>
                            </div>
                        ))
                    ) : (
                        <p className="col-span-full text-lg">No se ha trabajado sobre este albarán todavía.</p>
                    )}
                </div>
            ) : (
                <p className="col-span-full text-lg">Cargando albarán...</p>
            )}
        </div>
    );
};

export default ProjectDetailView;