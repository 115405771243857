import api from './api';

export const login = async (email, passwordhash) => {
    try {
        const response = await api.post('/api/admin/authenticate', {
            email,
            passwordhash
        });

        // return both Token and Role as an object
        return {
            token: response.data.Token,
            role: response.data.RoleName,
        };
    } catch (error) {
        if (error.response && error.response.status && error.response.message) {
            // Extract the custom message from the API response
            const errorMessage = error.response.message;
            throw new Error(errorMessage);
        } else {
            // Throw the original error if it does not have status
            throw error;
        }
    }
};