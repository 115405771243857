export const formatElapsedTime = (elapsedTime) => {
    const hours = Math.floor(elapsedTime / 3600000);
    const minutes = Math.floor((elapsedTime % 3600000) / 60000);
    const seconds = Math.floor((elapsedTime % 60000) / 1000);

    return `${hours}h ${minutes}m ${seconds}s`;
};

export const formatElapsedTimeHM = (startTime, endTime) => {
    const elapsedTime = new Date(endTime).getTime() - new Date(startTime).getTime();
    const hours = Math.floor(elapsedTime / 3600000);
    const minutes = Math.floor((elapsedTime % 3600000) / 60000);

    return `${hours}h ${minutes}m`;
};

export const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
};

export const formatDate1 = (dateString) => {
    const date = new Date(dateString);
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const hour = String(date.getHours()).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const year = date.getFullYear();
    return `${day}/${month}/${year} ${hour}:${minutes}`;
};

export function getButtonColor(serieAlbaran) {
    switch (serieAlbaran) {
        case 'B':
            return '!bg-yellow-600';
        case 'CH':
            return '!bg-green-700';
        case 'FA':
            return '!bg-red-700';
        case 'OE':
            return '!bg-purple-700';
        case 'OT':
            return '!bg-blue-800';
        case 'RF':
            return '!bg-indigo-700';
        case 'T':
            return '!bg-pink-600';
        default:
            return '!bg-gray-500';
    }
}

/*
public enum AbsenceType
{
    Vacaciones,
    Baja,
    Medico,
    Permiso,
    Excedencia,
    Otros_Asuntos
}
 */


export function getButtonColorAbsences(absence) {
    switch (absence.Type) {
        case 'Vacaciones':
            return '!bg-yellow-600';
        case 'Baja':
            return '!bg-green-700';
        case 'Medico':
            return '!bg-red-700';
        case 'Permiso':
            return '!bg-purple-700';
        case 'Excedencia':
            return '!bg-blue-800';
        case 'Otros_Asuntos':
            return '!bg-indigo-700';
        default:
            return '!bg-gray-500';
    }
}

/*
const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.getMonth() + 1; // Months are zero-based
    const year = date.getFullYear();
    return `${day} de ${monthNames[month - 1]} ${year}`;
};

const monthNames = [
    'Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio',
    'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'
];
*/