import {saveAs} from 'file-saver';
import XLSX from 'xlsx';
import {formatDate1} from "./utils";
import {getAbsencesByUserId} from './absenceService';
import dayjs from 'dayjs';
import Holidays from 'date-holidays';

const hd = new Holidays();
hd.init('ES', 'MA'); // ES for Spain, MA for Madrid

function isHoliday(date) {
    return hd.isHoliday(new Date(date));
}

function isWeekend(date) {
    const day = dayjs(date).day();
    return day === 0 || day === 6; // Sunday or Saturday
}

function tasksToSheet(data) {
    // Map the data to include only the necessary columns
    let dataClone = [...data];
    return dataClone.map(task => ({
        'Nombre': task?.User?.FirstName + " " + task?.User?.LastName,
        EjercicioAlbaran: task?.EjercicioAlbaran,
        SerieAlbaran: task?.SerieAlbaran,
        NumeroAlbaran: task?.NumeroAlbaran,
        'Hora inicio': formatDate1(task?.StartTime),
        'Hora final': (!task?.EndTime || task?.EndTime === 0) ? '' : formatDate1(task?.EndTime),
        Comentario: task?.WorkerComment,
        RazonSocial: task?.CabeceraAlbaranCliente?.RazonSocial,
        Matricula: task?.CabeceraAlbaranCliente?.Matricula,
    }));
}

// Helper function to round to nearest quarter hour
function roundToQuarter(num) {
    return Math.round(num * 4) / 4;
}

const exportHistoryToExcel = async (data, userId, startDate, endDate) => {
    try {
        // Set the hours rate
        const hoursRate = {
            EXTRAS_NORMALES: 10,
            EXTRA_ESPECIAL: 24,
            EXTRAS_SABADO: 15,
            DESTAJO_GRANDE: 200,
            DESTAJO_PEQUEÑO: 150,
            COMIDAS: 12,
            PLUS_ORG: 120
        };

        // Initialize the output object
        const output = {
            EXTRAS_NORMALES: 0,
            EXTRA_ESPECIAL: 0,
            EXTRAS_SABADO: 0,
            DESTAJO_GRANDE: 0,
            DESTAJO_PEQUEÑO: 0,
            COMIDAS: 0,
            PLUS_ORG: 0,
        };

        // Create a map to hold the daily data
        const dailyData = new Map();

        // Initialize alerts array
        const alerts = [];

        // Fetch absences for the user
        const absences = await getAbsencesByUserId(userId, startDate, endDate);

        // Process the data
        for (const task of data) {
            const startTime = new Date(task.StartTime);
            const endTime = new Date(task.EndTime);
            if (endTime == null) continue;
            let hours = (endTime - startTime) / 36e5; // Convert to hours

            // Correct if endTime is earlier than startTime
            if (hours < 0) {
                continue;
            }

            let dayString = startTime.toISOString().split('T')[0];
            let dayData = dailyData.get(dayString) || {
                TOTAL_HORAS: 0,
                HORAS_ORDINARIAS: 0,
                EXTRA_NORMALES: 0,
                EXTRA_SABADO: 0,
                COMIDA: 0,
                VACACIONES: 0,
                AUSENCIA: 0,
                IS_HOLIDAY: false,
                IS_VACATION: false
            };

            // If there's work done, process it regardless of whether it's a holiday
            if (!isWeekend(dayString)) {
                dayData.TOTAL_HORAS = roundToQuarter(dayData.TOTAL_HORAS + hours);

                // Calculate regular and extra hours
                if (dayData.TOTAL_HORAS <= 8) {
                    dayData.HORAS_ORDINARIAS = roundToQuarter(dayData.HORAS_ORDINARIAS + hours);
                } else if (dayData.HORAS_ORDINARIAS < 8) {
                    const remainingRegular = 8 - dayData.HORAS_ORDINARIAS;
                    dayData.HORAS_ORDINARIAS = 8;
                    dayData.EXTRA_NORMALES = roundToQuarter(dayData.EXTRA_NORMALES + hours - remainingRegular);
                } else {
                    dayData.EXTRA_NORMALES = roundToQuarter(dayData.EXTRA_NORMALES + hours);
                }

                // Check for lunch
                if (
                    ((task.SerieAlbaran === 'T' && task.WorkerComment === 'SALIDA DIRECCIÓN')
                        || task.SerieAlbaran === 'OE')
                    && (startTime.getHours() <= 13 && endTime.getHours() >= 14)
                ) {
                    dayData.COMIDA += 1;
                }

                // Check for alerts
                if (dayData.EXTRA_NORMALES > 2.5) {
                    alerts.push({
                        Fecha: dayString,
                        Comentario: `${dayData.EXTRA_NORMALES} horas extras entresemana`,
                        Filtro: '(Superior a 2.50 horas extras entresemana)'
                    });
                }
            } else if (isWeekend(dayString)) {
                dayData.EXTRA_SABADO = roundToQuarter(dayData.EXTRA_SABADO + hours);
                dayData.TOTAL_HORAS = roundToQuarter(dayData.TOTAL_HORAS + hours);
                if (dayData.EXTRA_SABADO > 8.5) {
                    alerts.push({
                        Fecha: dayString,
                        Comentario: `${dayData.EXTRA_SABADO} horas totales sábado`,
                        Filtro: '(Superior a 8.50 horas totales en sábado)'
                    });
                }
            }

            dailyData.set(dayString, dayData);
        }

        // Process holidays and absences
        for (const [dayString, dayData] of dailyData) {
            if (!isWeekend(dayString) && dayData.TOTAL_HORAS === 0) {
                if (isHoliday(dayString)) {
                    dayData.IS_HOLIDAY = true;
                    dayData.TOTAL_HORAS = 8;
                    dayData.HORAS_ORDINARIAS = 8;
                }
            }
        }

        // Process absences
        for (const absence of absences) {
            const absenceStart = new Date(absence.StartDate);
            const absenceEnd = new Date(absence.EndDate || absence.StartDate);
            let currentDate = new Date(absenceStart);

            while (currentDate <= absenceEnd) {
                const dayString = currentDate.toISOString().split('T')[0];
                let dayData = dailyData.get(dayString) || {
                    TOTAL_HORAS: 0,
                    HORAS_ORDINARIAS: 0,
                    EXTRA_NORMALES: 0,
                    EXTRA_SABADO: 0,
                    COMIDA: 0,
                    VACACIONES: 0,
                    AUSENCIA: 0,
                    IS_HOLIDAY: false,
                    IS_VACATION: false
                };

                if (!isWeekend(dayString) && !dayData.IS_HOLIDAY) {
                    switch (absence.Type) {
                        case 'Vacaciones':
                            dayData.VACACIONES = 1;
                            dayData.IS_VACATION = true;
                            dayData.TOTAL_HORAS = 8;
                            dayData.HORAS_ORDINARIAS = 8;
                            break;
                        case 'Baja':
                        case 'Medico':
                        case 'Permiso':
                        case 'Excedencia':
                        case 'Otros_Asuntos':
                            const expectedHours = 8;
                            if (dayData.TOTAL_HORAS < expectedHours) {
                                const absenceHours = expectedHours - dayData.TOTAL_HORAS;
                                dayData.AUSENCIA = roundToQuarter(dayData.AUSENCIA + absenceHours);
                                dayData.TOTAL_HORAS = roundToQuarter(dayData.TOTAL_HORAS + absenceHours);
                                dayData.HORAS_ORDINARIAS = roundToQuarter(dayData.HORAS_ORDINARIAS + absenceHours);
                            }
                            break;
                    }
                }

                dailyData.set(dayString, dayData);
                currentDate.setDate(currentDate.getDate() + 1);
            }
        }

        // Convert the daily data to an array and sort by date
        const dailyArray = Array.from(dailyData, ([date, data]) => ({
            FECHA: date,
            TOTAL_HORAS: data.IS_HOLIDAY ? 'FESTIVO' : (data.IS_VACATION ? 'VACACIONES' : (data.TOTAL_HORAS || '')),
            HORAS_ORDINARIAS: data.IS_HOLIDAY || data.IS_VACATION ? '' : (data.HORAS_ORDINARIAS || ''),
            EXTRA_NORMALES: data.IS_HOLIDAY || data.IS_VACATION ? '' : (data.EXTRA_NORMALES || ''),
            EXTRA_SABADO: data.IS_HOLIDAY || data.IS_VACATION ? '' : (data.EXTRA_SABADO || ''),
            COMIDA: data.IS_HOLIDAY || data.IS_VACATION ? '' : (data.COMIDA || ''),
            VACACIONES: data.IS_VACATION ? 1 : (data.VACACIONES || ''),
            AUSENCIA: data.IS_HOLIDAY || data.IS_VACATION ? '' : (data.AUSENCIA || '')
        }))
            .filter(day => {
                const dayDate = new Date(day.FECHA);
                return dayDate >= new Date(startDate) && dayDate <= new Date(endDate);
            })
            .filter(day => {
                // Keep the row if it has any non-empty value other than FECHA
                return Object.values(day).some((value, index) => index !== 0 && value !== '');
            })
            .sort((a, b) => new Date(a.FECHA) - new Date(b.FECHA));

        // Calculate totals
        const totals = dailyArray.reduce((acc, day) => {
            Object.keys(day).forEach(key => {
                if (key !== 'FECHA' && typeof day[key] === 'number' && day[key] !== 0) {
                    acc[key] = roundToQuarter((acc[key] || 0) + day[key]);
                }
            });
            return acc;
        }, {});

        // Prepare the summary data
        const summaryData = [
            {
                CONCEPTO: 'EXTRAS NORMALES',
                CANTIDAD: totals.EXTRA_NORMALES || '',
                PRECIO: hoursRate.EXTRAS_NORMALES,
                TOTAL: totals.EXTRA_NORMALES ? totals.EXTRA_NORMALES * hoursRate.EXTRAS_NORMALES : ''
            },
            {
                CONCEPTO: 'EXTRA ESPECIAL',
                CANTIDAD: output.EXTRA_ESPECIAL || '',
                PRECIO: hoursRate.EXTRA_ESPECIAL,
                TOTAL: output.EXTRA_ESPECIAL ? output.EXTRA_ESPECIAL * hoursRate.EXTRA_ESPECIAL : ''
            },
            {
                CONCEPTO: 'EXTRAS SABADO',
                CANTIDAD: totals.EXTRA_SABADO || '',
                PRECIO: hoursRate.EXTRAS_SABADO,
                TOTAL: totals.EXTRA_SABADO ? totals.EXTRA_SABADO * hoursRate.EXTRAS_SABADO : ''
            },
            {
                CONCEPTO: 'DESTAJO GRANDE',
                CANTIDAD: output.DESTAJO_GRANDE || '',
                PRECIO: hoursRate.DESTAJO_GRANDE,
                TOTAL: output.DESTAJO_GRANDE ? output.DESTAJO_GRANDE * hoursRate.DESTAJO_GRANDE : ''
            },
            {
                CONCEPTO: 'DESTAJO PEQUEÑO',
                CANTIDAD: output.DESTAJO_PEQUEÑO || '',
                PRECIO: hoursRate.DESTAJO_PEQUEÑO,
                TOTAL: output.DESTAJO_PEQUEÑO ? output.DESTAJO_PEQUEÑO * hoursRate.DESTAJO_PEQUEÑO : ''
            },
            {
                CONCEPTO: 'COMIDAS',
                CANTIDAD: totals.COMIDA || '',
                PRECIO: hoursRate.COMIDAS,
                TOTAL: totals.COMIDA ? totals.COMIDA * hoursRate.COMIDAS : ''
            },
            {
                CONCEPTO: 'PLUS ORG.',
                CANTIDAD: output.PLUS_ORG || '',
                PRECIO: hoursRate.PLUS_ORG,
                TOTAL: output.PLUS_ORG ? output.PLUS_ORG * hoursRate.PLUS_ORG : ''
            },
        ];

        // Calculate the grand total
        const grandTotal = summaryData.reduce((total, item) => total + (item.TOTAL || 0), 0);
        summaryData.push({CONCEPTO: 'TOTAL', CANTIDAD: '', PRECIO: '', TOTAL: grandTotal || ''});


        // Create a new workbook
        const wb = XLSX.utils.book_new();

        // Create the daily data worksheet
        const ws1 = XLSX.utils.json_to_sheet([
            {FECHA: 'TOTAL', ...totals},
            ...dailyArray
        ], {header: ['FECHA', 'TOTAL_HORAS', 'HORAS_ORDINARIAS', 'EXTRA_NORMALES', 'EXTRA_SABADO', 'COMIDA', 'VACACIONES', 'AUSENCIA']});

        // Merge cells for FESTIVO and VACACIONES rows
        dailyArray.forEach((day, index) => {
            if (day.TOTAL_HORAS === 'FESTIVO' || day.TOTAL_HORAS === 'VACACIONES') {
                const rowIndex = index + 3; // +3 because of header and totals rows
                XLSX.utils.sheet_add_aoa(ws1, [[day.TOTAL_HORAS]], {origin: `B${rowIndex}`});
                ws1['!merges'] = ws1['!merges'] || [];
                ws1['!merges'].push({s: {r: rowIndex - 1, c: 1}, e: {r: rowIndex - 1, c: 7}});
            }
        });

        // Create the summary worksheet
        const ws2 = XLSX.utils.json_to_sheet(summaryData, {header: ['CONCEPTO', 'CANTIDAD', 'PRECIO', 'TOTAL']});

        // Create the tasks worksheet
        const ws3 = XLSX.utils.json_to_sheet(tasksToSheet(data), {header: ['Nombre', 'EjercicioAlbaran', 'SerieAlbaran', 'NumeroAlbaran', 'Hora inicio', 'Hora final', 'Comentario', 'RazonSocial', 'Matricula']});

        // Create the alerts worksheet
        const ws4 = XLSX.utils.json_to_sheet(alerts, {header: ['Fecha', 'Comentario', 'Filtro']});

        // Add styling
        const dailyStyle = {
            '!cols': [
                {wch: 12},
                {wch: 12},
                {wch: 18},
                {wch: 15},
                {wch: 13},
                {wch: 8},
                {wch: 12},
                {wch: 10}
            ],
            '!rows': [{hpt: 30}, {hpt: 30}], // Make the header and total rows taller
        };

        const summaryStyle = {
            '!cols': [
                {wch: 20},
                {wch: 10},
                {wch: 10},
                {wch: 10}
            ],
            '!rows': [{hpt: 30}], // Make the header row taller
        };

        // Apply styles
        ws1['!cols'] = dailyStyle['!cols'];
        ws1['!rows'] = dailyStyle['!rows'];
        ws2['!cols'] = summaryStyle['!cols'];
        ws2['!rows'] = summaryStyle['!rows'];

        // Add the worksheets to the workbook
        XLSX.utils.book_append_sheet(wb, ws1, 'Desglose Diario');
        XLSX.utils.book_append_sheet(wb, ws2, 'Resumen');
        XLSX.utils.book_append_sheet(wb, ws3, 'Entradas temporales');
        XLSX.utils.book_append_sheet(wb, ws4, 'Alertas');

        // Generate the Excel file
        const wbout = XLSX.write(wb, {bookType: 'xlsx', type: 'binary'});

        // Convert to Blob and save
        const blob = new Blob([s2ab(wbout)], {type: 'application/octet-stream'});
        saveAs(blob, `Informe_${data[0]?.User?.FirstName || ''}_${data[0]?.User?.LastName || ''}.xlsx`);

    } catch (e) {
        console.error(e);
    }
};

// Helper function to convert string to ArrayBuffer
function s2ab(s) {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xFF;
    return buf;
}

export {exportHistoryToExcel};