import api from "./api";

export const getAvailableProjects = async (UserID) => {
    try {
        const response = await api.get('/api/projects/notcompleted');
        return response.data;
    } catch (error) {
        throw new Error('Error fetching available projects:', error);
    }
};

export const getProjectDetails = async (project) => {
    const ejercicioAlbaran = project.EjercicioAlbaran;
    const serieAlbaran = project.SerieAlbaran;
    const numeroAlbaran = project.NumeroAlbaran;
    try {
        const response = await api.get(`/api/projects/${ejercicioAlbaran}/${serieAlbaran}/${numeroAlbaran}`);
        return response.data;
    } catch (error) {
        throw new Error('Error fetching project details:', error);
    }
}

export const getAvailableProjectsPaginated = async (page, pageSize, ejercicio, serie, numero) => {
    const params = new URLSearchParams({
        pageNumber: page,
        pageSize: pageSize,
        ...(ejercicio && {ejercicio}),
        ...(serie && {serie}),
        ...(numero && {numero})
    });

    try {
        const response = await api.get(`/api/projects?${params}`);
        return response.data;
    } catch (error) {
        throw new Error('Error fetching available projects:', error);
    }
};

export const startTask = async (UserID, CodigoEmpresa, EjercicioAlbaran, SerieAlbaran, NumeroAlbaran) => {
    try {
        const response = await api.post('/api/timeentries', {
            UserID,
            CodigoEmpresa,
            EjercicioAlbaran,
            SerieAlbaran,
            NumeroAlbaran
        });
        return response.data;
    } catch (error) {
        const customError = new Error('CustomError');
        if (error.response) {
            // Handle error response
            customError.code = error.response.data.ErrorCode;
            customError.message = error.response.data.Message;

            // Switch case for different error codes
            /*
            switch (error.response.data.ErrorCode) {
                case 1:
                    break;
                case 2:
                    break;
                default:
                    break;
            }
            */
        } else {
            // Handle other errors (e.g., network errors)
            customError.message = error.message;
        }
        throw customError;
    }
};

/*
    //Admin time entry creation
    // POST: api/timeentries/admin
    [HttpPost("admin")]
    public async Task<ActionResult<TimeEntry>> CreateTimeEntryAdmin(TimeEntryCreate timeEntryCreate)
 */

export const startTaskAdmin = async (timeEntryCreate) => {
    try {
        const response = await api.post('/api/timeentries/admin', timeEntryCreate);
        return response.data;
    } catch (error) {
        const customError = new Error('CustomError');
        if (error.response) {
            // Handle error response
            customError.code = error.response.data.ErrorCode;
            customError.message = error.response.data.Message;
        } else {
            // Handle other errors (e.g., network errors)
            customError.message = error.message;
        }
        throw customError;
    }
}