import React, { useState } from 'react';
import {Label, TextInput} from "flowbite-react";

const LoginForm = ({ onLogin }) => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        onLogin(username, password);
    };

    return (
        <form onSubmit={(e) => handleSubmit(e)}>
            <h2 className="font-medium mb-8">Login</h2>
            <div className="flex flex-col gap-4">
                <div className="flex flex-row gap-5 justify-between items-center">
                    <label className="text-xl" htmlFor="username">Usuario:</label>
                    <input
                        className="w-full text-lg"
                        type="text"
                        id="username"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                    />
                </div>
                <div className="flex flex-row gap-5 justify-between items-center">
                    <label className="text-xl" htmlFor="password">Contraseña:</label>
                    <input
                        className="w-full text-lg"
                        type="password"
                        id="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                </div>
                <button
                    className="mt-4 brand w-full h-30 flex flex-shrink-0 content-center items-center justify-center rounded-sm border border-white/20 px-6 py-3 text-center text-xl font-medium text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2"
                    type="submit">Login
                </button>
            </div>
        </form>
    );
};

export default LoginForm;