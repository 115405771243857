import { useRef, useEffect } from 'react';
import {Label, Modal, TextInput} from "flowbite-react";

function EditTaskModal({ selectedTask, onEditTask, setSelectedTask }) {
    const startTimeRef = useRef(null);
    const endTimeRef = useRef(null);

    const { TimeEntryID, StartTime, EndTime } = selectedTask || {};

    useEffect(() => {
        if (startTimeRef.current) startTimeRef.current.value = StartTime?.slice(0, 16) || '';
        if (endTimeRef.current) endTimeRef.current.value = EndTime?.slice(0, 16) || '';
    }, [StartTime, EndTime]);

    const resetEditTaskModal = () => {
        setSelectedTask(null);
        if (startTimeRef.current) startTimeRef.current.value = '';
        if (endTimeRef.current) endTimeRef.current.value = '';
    }

    return (
        <Modal show={!!selectedTask} onClose={resetEditTaskModal} size="lg" position="center">
            <Modal.Header>Editando tarea</Modal.Header>
            <Modal.Body>
                <div className="flex flex-col gap-4 mt-2">
                    <Label htmlFor="firstName">
                        Fecha inicio
                    </Label>
                    <TextInput type="datetime-local" id="firstName" className="w-full rounded-md" ref={startTimeRef}/>
                </div>
                <div className="flex flex-col gap-4 mt-2">
                    <Label htmlFor="lastName">
                        Fecha finalización
                    </Label>
                    <TextInput type="datetime-local" id="lastName" className="w-full rounded-md" ref={endTimeRef}/>
                </div>
            </Modal.Body>

            <Modal.Footer>
                <button onClick={() => {
                    onEditTask(TimeEntryID, {
                        StartTime: startTimeRef.current.value + ":00",
                        EndTime: endTimeRef.current.value + ":00",
                    });

                    resetEditTaskModal();
                }}
                        className="inline-flex text-lg justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-800 text-white hover:bg-blue-900 focus:outline-none sm:w-auto lg:text-xl"
                >
                    Confirmar cambios
                </button>
            </Modal.Footer>
        </Modal>
    );
}

export default EditTaskModal;