import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <div className="bg-dgray-100 min-h-screen">
            <div className="container mx-auto p-3 md:p-8 flex flex-col items-center justify-center min-h-[100vh]">
                <div className="text-center mb-8">
                    <img src="/logo.png" alt="Ekipman Logo" className="order-1 aspect-auto w-[450px]"/>
                </div>
                <App/>
                <div className="text-center mt-6 flex flex-col gap-3">
                    <p className="text-lg"> Copyright © 2023 Ekipman y Servicios, S.L.</p>
                </div>
            </div>
        </div>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
