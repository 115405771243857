import api from './api';

// Helper function to format date to ISO string with timezone offset
const formatDateToISOString = (date) => {
    return new Date(date).toISOString();
};

// Get absence by ID
export const getAbsenceById = async (id) => {
    try {
        const response = await api.get(`/api/absences/${id}`);
        return response.data;
    } catch (error) {
        throw new Error('Error fetching absence: ' + error);
    }
};

// Get all absences with optional date range
export const getAbsences = async (startDate, endDate) => {
    try {
        let url = '/absences';
        const params = new URLSearchParams();
        if (startDate) params.append('startDate', formatDateToISOString(new Date(startDate)));
        if (endDate) params.append('endDate', formatDateToISOString(new Date(endDate)));
        if (params.toString()) url += `?${params.toString()}`;

        const response = await api.get(url);
        return response.data;
    } catch (error) {
        throw new Error('Error fetching absences: ' + error);
    }
};

// Create a new absence
export const createAbsence = async (absenceData) => {
    try {
        const formattedAbsenceData = {
            ...absenceData,
            startDate: formatDateToISOString(new Date(absenceData.startDate)),
            endDate: absenceData.endDate ? formatDateToISOString(new Date(absenceData.endDate)) : null,
        };
        const response = await api.post('/api/absences', formattedAbsenceData);
        return response.data;
    } catch (error) {
        if (error.response && error.response.data) {
            // If the error has a response from the server
            throw new Error(error.response.data.Message || JSON.stringify(error.response.data));
        } else if (error.request) {
            // The request was made but no response was received
            throw new Error('No response received from the server');
        } else {
            // Something happened in setting up the request that triggered an Error
            throw new Error(error.message || 'An unexpected error occurred');
        }
    }
};

// Update an existing absence
export const updateAbsence = async (id, absenceData) => {
    try {
        const formattedAbsenceData = {
            ...absenceData,
            startDate: absenceData.startDate ? formatDateToISOString(new Date(absenceData.startDate)) : undefined,
            endDate: absenceData.endDate ? formatDateToISOString(new Date(absenceData.endDate)) : undefined,
        };
        const response = await api.put(`/api/absences/${id}`, formattedAbsenceData);
        return response.data;
    } catch (error) {
        throw new Error('Error updating absence: ' + error);
    }
};

// Delete an absence
export const deleteAbsence = async (id) => {
    try {
        const response = await api.delete(`/api/absences/${id}`);
        return response.data;
    } catch (error) {
        throw new Error('Error deleting absence: ' + error);
    }
};

// Get absences by user ID with optional date range
export const getAbsencesByUserId = async (userId, startDate, endDate) => {
    try {
        let url = `/api/absences/user/${userId}`;
        const params = new URLSearchParams();
        if (startDate) params.append('startDate', formatDateToISOString(new Date(startDate)));
        if (endDate) params.append('endDate', formatDateToISOString(new Date(endDate)));
        if (params.toString()) url += `?${params.toString()}`;

        const response = await api.get(url);
        return response.data;
    } catch (error) {
        throw new Error('Error fetching absences for user: ' + error);
    }
};

// Create absence with formatted dates (alternative method for specific use cases)
export const createFormattedAbsence = async (userId, type, startDate, endDate) => {
    try {
        const formattedStartDate = formatDateToISOString(new Date(startDate));
        const formattedEndDate = endDate ? formatDateToISOString(new Date(endDate)) : null;

        const absenceData = {
            userId,
            type,
            startDate: formattedStartDate,
            endDate: formattedEndDate
        };

        const response = await api.post('/api/absences', absenceData);
        return response.data;
    } catch (error) {
        throw new Error('Error creating formatted absence: ' + error);
    }
};