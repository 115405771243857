import api from './api';

export const finishTask = async (taskId) => {
    try {
        const endTime = new Date();
        const offset = endTime.getTimezoneOffset(); // -120 (minutes)
        const endTimeUTC = endTime.getTime() - offset * 60 * 1000; // 1620671760687 (milliseconds)
        const endTimeString = new Date(endTimeUTC).toISOString(); // "2023-05-10T19:16:00.687Z"
        const response = await api.put(`/api/timeentries/${taskId}`, {
            endTime: endTimeString,
        });
        return response.data;
    } catch (error) {
        throw new Error('Error finishing task: ' + error);
    }
};

export const finishTaskWithComment = async (taskId, comment) => {
    try {
        const endTime = new Date();
        const offset = endTime.getTimezoneOffset(); // -120 (minutes)
        const endTimeUTC = endTime.getTime() - offset * 60 * 1000; // 1620671760687 (milliseconds)
        const endTimeString = new Date(endTimeUTC).toISOString(); // "2023-05-10T19:16:00.687Z"
        const response = await api.put(`/api/timeentries/${taskId}`, {
            endTime: endTimeString,
            workerComment: comment
        });
        return response.data;
    } catch (error) {
        throw new Error('Error finishing task: ' + error);
    }
};

export const removeTask = async (taskId) => {
    try {
        const response = await api.delete(`/api/timeentries/${taskId}`);
        return response.data;
    } catch (error) {
        throw new Error('Error removing task: ' + error);
    }
};

export const updateTask = async (taskId, task) => {
    try {
        const response = await api.put(`/api/timeentries/${taskId}`, task);
        return response.data;
    } catch (error) {
        throw new Error('Error updating task: ' + error);
    }
};

export const sendTaskForReview = async (taskId, workerComment) => {
    try {
        const response = await api.put(`/api/timeentries/${taskId}`, {
            needReview: true,
            workerComment,
        });
        return response.data;
    } catch (error) {
        throw new Error('Error sending task for review: ' + error);
    }
};

export const getAllUnfinishedTasks = async () => {
    try {
        const response = await api.get('/api/timeentries/unfinished');
        return response.data;
    } catch (error) {
        throw new Error('Error fetching available projects: ' + error);
    }
};

export const endAllUnfinishedTimeEntries = async () => {
    try {
        const response = await api.put('/api/timeentries/endall');
        return response.data;
    } catch (error) {
        throw new Error('Error ending all unfinished time entries: ' + error);
    }
};

export const getTasksByUserAndTimeRange = async (userId, startTime, endTime) => {
    try {
        const response = await api.get(`/api/timeentries/userid/${userId}`, {
            params: {
                StartTime: startTime,
                EndTime: endTime,
            },
        });
        return response.data;
    } catch (error) {
        throw new Error('Error al recuperar las tareas: ' + error);
    }
};

export const getTasksByUser = async (userId) => {
    try {
        const response = await api.get(`/api/timeentries/userid/${userId}`);
        return response.data;
    } catch (error) {
        throw new Error('Error al cargar las tareas: ' + error);
    }
};

export const getTaskById = async (taskId) => {
    try {
        const response = await api.get(`/api/timeentries/${taskId}`);
        return response.data;
    } catch (error) {
        throw new Error('Error fetching task details: ' + error);
    }
};