import React from 'react';

const Pagination = ({ currentPage, totalItems, pageSize, onPageChange }) => {
    const totalPages = Math.ceil(totalItems / pageSize);

    const getVisiblePages = (currentPage, totalPages) => {
        const range = [];

        // Always add the first page
        range.push(1);

        // Add ellipsis if there are more than 2 pages between the first page and the current page
        if (currentPage - 1 > 2) {
            range.push('...');
        }

        // Calculate the range around the current page
        let startPage = Math.max(2, Math.min(currentPage - 1, totalPages - 3));
        let endPage = Math.min(totalPages - 1, Math.max(currentPage + 1, 4));

        for (let i = startPage; i <= endPage; i++) {
            range.push(i);
        }

        // Add ellipsis and the last page if there are more than 2 pages between the current page and the last page
        if (currentPage + 2 < totalPages) {
            range.push('...');
        }

        if (totalPages > 1) {
            range.push(totalPages);
        }

        return range;
    };

    const visiblePages = getVisiblePages(currentPage, totalPages);

    if (totalPages === 0) return null;

    return (
        <nav aria-label="Pagination" className="flex my-5 justify-center">
            <ul className="flex items-center justify-center align-middle -space-x-px">
                <li className={"mt-2"}>
                    <button
                        onClick={() => onPageChange(currentPage - 1)}
                        disabled={currentPage === 1}
                        className={`m-0 px-3 py-1 leading-tight text-gray-500 rounded-l-md hover:text-gray-700 ${currentPage === 1 && 'cursor-not-allowed'}`}
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M560-240 320-480l240-240 56 56-184 184 184 184-56 56Z"/></svg>
                    </button>
                </li>
                {visiblePages.map((page, index) => (
                    <li key={index}>
                        {page === '...' ? (
                            <span className="px-3 py-1 text-gray-500">...</span>
                        ) : (
                            <button
                                onClick={() => onPageChange(page)}
                                className={`px-3 py-1 leading-tight rounded-none text-base ${page === currentPage ? 'bg-blue-500 text-white' : 'text-gray-700 bg-white'} border border-gray-300 hover:bg-blue-500 hover:text-white`}
                            >
                                {page}
                            </button>
                        )}
                    </li>
                ))}
                <li>
                    <button
                        onClick={() => onPageChange(currentPage + 1)}
                        disabled={currentPage === totalPages}
                        className={`px-3 py-1 leading-tight text-gray-500 rounded-r-md hover:text-gray-700 ${currentPage === totalPages && 'cursor-not-allowed'}`}
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M504-480 320-664l56-56 240 240-240 240-56-56 184-184Z"/></svg>
                    </button>
                </li>
            </ul>
        </nav>
    );
};

export default Pagination;