import React, {useState, useEffect} from 'react';
import {getTasksByUserAndTimeRange, removeTask, updateTask} from '../../services/taskService';
import {toast} from "react-toastify";
import {exportHistoryToExcel} from "../../services/excelService";
import {getButtonColor, formatDate1, formatElapsedTimeHM} from "../../services/utils";
import EditTaskModal from "./EditTaskModal";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import {esES} from '@mui/x-date-pickers/locales';
import dayjs from "dayjs";
import 'dayjs/locale/es';
import {getTasksByTimeEntryID} from "../../services/subTimeEntriesService";

const TaskHistory = ({workerId, role}) => {
    const [tasks, setTasks] = useState([]);
    const [startTime, setStartTime] = useState(dayjs().startOf('month'));
    const [endTime, setEndTime] = useState(dayjs().endOf('day'));

    const [selectedTask, setSelectedTask] = useState(null);

    const fetchTasks = async () => {
        try {
            let endTimeFix = dayjs(endTime).endOf('day');

            let tasks = await getTasksByUserAndTimeRange(workerId, startTime, endTimeFix);

            if (!Array.isArray(tasks)) {
                console.error('getTasksByUserAndTimeRange did not return an array:', tasks);
                tasks = [];
            }

            // Sort tasks by StartTime from newer to older
            tasks = tasks.sort((a, b) => new Date(b.StartTime) - new Date(a.StartTime));

            // Fetch SubTimeEntries for each task
            try {
                for (let task of tasks) {
                    task.subTimeEntries = await getTasksByTimeEntryID(task.TimeEntryID);
                }
            } catch (error) {
                console.error('Error fetching sub time entries for tasks:', error);
            }

            setTasks(tasks);

        } catch (error) {
            toast.error('Error al cargar las tareas, avisa a un administrador: ', error.message);
        }
    };

    useEffect(() => {
        fetchTasks();
    }, [workerId, startTime, endTime]);


    const editTask = async (id, task) => {
        try {
            console.log(JSON.stringify(task))
            const updatedTask = await updateTask(id, task);

            let updatedTasks = tasks.map(task => task.TimeEntryID === id ? updatedTask : task);

            setTasks(updatedTasks);
        } catch (error) {
            console.error('Error updating task:', error);
        }
    }

    const handleEditClick = (task) => {
        setSelectedTask(task);
    };

    const handleRemoveClick = (taskid) => {
        toast(({closeToast}) =>
                <div>
                    <p>¿Seguro que quieres eliminar esta tarea?
                        <button className="ml-7 text-white !bg-green-700"
                                onClick={() => {

                                    removeTask(taskid)
                                        .then(() => {
                                            setTasks(tasks.filter(task => task.TimeEntryID !== taskid));
                                            toast.success("Tarea eliminada correctamente");
                                        })
                                        .catch((error) => {
                                            toast.error("Error al eliminar la tarea: " + error);
                                        })
                                        .finally(() => {
                                            closeToast();
                                        })
                                }}
                        >
                            Confirmar
                        </button>
                    </p>
                </div>,
            {type: toast.TYPE.INFO}
        )
    };

    const handleExport = async () => {
        await exportHistoryToExcel(tasks, workerId, startTime, endTime);
    };

    return (
        <div>
            <EditTaskModal selectedTask={selectedTask} setSelectedTask={setSelectedTask} onEditTask={editTask}/>
            <div className="mb-5 flex flex-row gap-2 justify-between items-end">
                <div>
                    <label className="flex flex-col gap-2">
                        <p className="mr-3 inline text-xl sm:text-2xl">Periodo:</p>
                    </label>
                </div>
                {role && role.toLowerCase() === "superadmin" ? (
                    <button
                        className="text-2xl sm:text-2xl !bg-green-800 md:hover:!bg-green-900 text-white font-bold py-2 px-4 rounded max-h-[60px] my-auto"
                        onClick={handleExport}
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50"
                             className="inline mr-3 mb-0.5 w-[16px] h-[16px] sm:w-[20px] sm:h-[20px]">
                            <path strokeWidth="2" fill="#ffffff"
                                  d="M 28.875 0 C 28.855469 0.0078125 28.832031 0.0195313 28.8125 0.03125 L 0.8125 5.34375 C 0.335938 5.433594 -0.0078125 5.855469 0 6.34375 L 0 43.65625 C -0.0078125 44.144531 0.335938 44.566406 0.8125 44.65625 L 28.8125 49.96875 C 29.101563 50.023438 29.402344 49.949219 29.632813 49.761719 C 29.859375 49.574219 29.996094 49.296875 30 49 L 30 44 L 47 44 C 48.09375 44 49 43.09375 49 42 L 49 8 C 49 6.90625 48.09375 6 47 6 L 30 6 L 30 1 C 30.003906 0.710938 29.878906 0.4375 29.664063 0.246094 C 29.449219 0.0546875 29.160156 -0.0351563 28.875 0 Z M 28 2.1875 L 28 6.53125 C 27.867188 6.808594 27.867188 7.128906 28 7.40625 L 28 42.8125 C 27.972656 42.945313 27.972656 43.085938 28 43.21875 L 28 47.8125 L 2 42.84375 L 2 7.15625 Z M 30 8 L 47 8 L 47 42 L 30 42 L 30 37 L 34 37 L 34 35 L 30 35 L 30 29 L 34 29 L 34 27 L 30 27 L 30 22 L 34 22 L 34 20 L 30 20 L 30 15 L 34 15 L 34 13 L 30 13 Z M 36 13 L 36 15 L 44 15 L 44 13 Z M 6.6875 15.6875 L 12.15625 25.03125 L 6.1875 34.375 L 11.1875 34.375 L 14.4375 28.34375 C 14.664063 27.761719 14.8125 27.316406 14.875 27.03125 L 14.90625 27.03125 C 15.035156 27.640625 15.160156 28.054688 15.28125 28.28125 L 18.53125 34.375 L 23.5 34.375 L 17.75 24.9375 L 23.34375 15.6875 L 18.65625 15.6875 L 15.6875 21.21875 C 15.402344 21.941406 15.199219 22.511719 15.09375 22.875 L 15.0625 22.875 C 14.898438 22.265625 14.710938 21.722656 14.5 21.28125 L 11.8125 15.6875 Z M 36 20 L 36 22 L 44 22 L 44 20 Z M 36 27 L 36 29 L 44 29 L 44 27 Z M 36 35 L 36 37 L 44 37 L 44 35 Z"/>
                        </svg>
                        Exportar
                    </button>
                ) : null}
            </div>
            <div className="flex flex-row gap-3 items-center mb-8">
                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es"
                                      localeText={esES.components.MuiLocalizationProvider.defaultProps.localeText}>
                    <DatePicker value={startTime} onChange={(newValue) => setStartTime(newValue)} className="inline"
                                inputFormat="DD/MM/YYYY"/>
                </LocalizationProvider>
                <p className="inline text-xl"> a </p>
                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es"
                                      localeText={esES.components.MuiLocalizationProvider.defaultProps.localeText}>
                    <DatePicker value={endTime} onChange={(newValue) => setEndTime(newValue)} className="inline"
                                inputFormat="DD/MM/YYYY"/>
                </LocalizationProvider>
            </div>
            <div className="grid grid-cols-1 auto-rows-max auto-cols-max gap-5 sm:grid-cols-2 md:grid-cols-3">
                {tasks && Array.isArray(tasks) && tasks.map((task) => (
                    <div
                        key={task.TimeEntryID}
                        className="flex flex-col"
                    >
                        {role && role.toLowerCase() === "superadmin" ? (
                            <div
                                className="w-full bg-gray-600 flex justify-end items-center p-1.5 rounded-t rounded-b-none">
                                <svg xmlns="http://www.w3.org/2000/svg"
                                     className="h-6 w-6 text-white cursor-pointer"
                                     viewBox="0 0 24 24"
                                     fill="none"
                                     onClick={() => handleEditClick(task)}
                                >
                                    <path fillRule="evenodd" clipRule="evenodd"
                                          d="M8.56078 20.2501L20.5608 8.25011L15.7501 3.43945L3.75012 15.4395V20.2501H8.56078ZM15.7501 5.56077L18.4395 8.25011L16.5001 10.1895L13.8108 7.50013L15.7501 5.56077ZM12.7501 8.56079L15.4395 11.2501L7.93946 18.7501H5.25012L5.25012 16.0608L12.7501 8.56079Z"
                                          fill="currentColor"/>
                                </svg>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="h-6 w-6 text-white cursor-pointer"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                    onClick={() => handleRemoveClick(task.TimeEntryID)}
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth={2}
                                        d="M6 18L18 6M6 6l12 12"
                                    />
                                </svg>
                            </div>
                        ) : null}
                        <button
                            className={`${getButtonColor(task.CabeceraAlbaranCliente.SerieAlbaran)} brand text-white font-bold !text-lg leading-relaxed py-2 px-4 w-full h-full text-center ${role && role.toLowerCase() === "superadmin" ? 'rounded-b rounded-t-none -mt-1' : 'rounded'}`}
                        >
                            {task.CabeceraAlbaranCliente.SerieAlbaran}-{task.CabeceraAlbaranCliente.NumeroAlbaran}<br/>
                            {task.CabeceraAlbaranCliente.RazonSocial}<br/>
                            {task.CabeceraAlbaranCliente.Matricula}<br/>
                            {task.WorkerComment &&
                                <>
                                    <br/>
                                    {task.WorkerComment}
                                    <br/>
                                </>
                            }
                            {task.subTimeEntries && task.subTimeEntries.length > 0 && (
                                <>
                                    {task.subTimeEntries.map((subTimeEntry) =>
                                            subTimeEntry.Task && subTimeEntry.Task.Name && (
                                                <>
                                                    <br/>
                                                    {subTimeEntry.Task.Name}
                                                </>
                                            )
                                    )}
                                    <br/>
                                </>
                            )}
                            <br/>
                            {formatDate1(task.StartTime)}
                            {task.EndTime &&
                                <>
                                    <br/>
                                    {formatDate1(task.EndTime)}
                                    <br/>
                                    {formatElapsedTimeHM(task.StartTime, task.EndTime)}
                                </>
                            }
                            {!task.EndTime &&
                                <>
                                    <br/>
                                    {"Tarea Activa"}
                                </>
                            }
                        </button>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default TaskHistory;