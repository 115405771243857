import React, {useEffect, useState} from 'react';
import LoginView from './views/LoginView/LoginView';
import WorkerSelectionView from './views/WorkerSelectionView/WorkerSelectionView';
import TaskDashboardView from './views/TaskDashboardView/TaskDashboardView';
import {login} from './services/authService';
import api from "./services/api";
import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {getSettings} from './services/settingsService';

export const SettingsContext = React.createContext(null);
export const WorkersContext = React.createContext(null);

function App() {
    const [token, setToken] = useState(null);
    const [role, setRole] = useState(null);
    const [worker, setWorker] = useState(null);
    const [task, setTask] = useState(null);
    const [workers, setWorkers] = useState([]);

    // Settings management
    const [settings, setSettings] = useState([]);

    //WorkerSelectionView.js
    const [viewType, setViewType] = useState('workers'); // 'workers' or 'projects'
    const [project, setProject] = useState(null);

    useEffect(() => {
        const storedToken = localStorage.getItem('token');
        if (storedToken) {
            setToken(storedToken);
        }
        const storedRole = localStorage.getItem('role');
        if (storedRole) {
            setRole(storedRole);
        }
    }, []);

    useEffect(() => {
        if (!token) {
            //setRole(null);
            setWorker(null);
            setTask(null);
        } else {
            //update settings
            fetchSettings();
        }
    }, [token]);

    const fetchSettings = async () => {
        const fetchedSettings = await getSettings();
        setSettings(fetchedSettings);
    };

    const handleLogin = async (username, password) => {
        const id = toast.loading("Iniciando sesión...");
        try {
            const {token, role} = await login(username, password);
            localStorage.setItem('token', token);
            setToken(token);
            localStorage.setItem('role', role);
            setRole(role);
            toast.update(id, {
                render: "Sesión iniciada correctamente", type: "success",
                position: "bottom-right",
                isLoading: false,
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light"
            });
        } catch (error) {
            toast.update(id, {
                render: "Error al iniciar sesión " + error.message, type: "error",
                position: "bottom-right",
                isLoading: false,
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light"
            });
        }
    };

    const handleLogout = () => {
        localStorage.removeItem('token');
        localStorage.removeItem('role');
        setToken(null);
        setRole(null);
        setWorker(null);
        setTask(null);
    };

    const handleWorkerSelection = async (selectedWorker) => {
        setWorker(selectedWorker);
        try {
            const response = await api.get(`/api/timeentries/user/${selectedWorker.UserID}/unfinished`);

            console.log(response.data);
            setTask(response.data);

        } catch (err) {
            console.error(err);
        }
    };

    const handleTaskStarted = (task) => {

        var albaran = task.CabeceraAlbaranCliente;
        var message = "Se ha establecido correctamente al trabajador " +
            worker.FirstName + " " + worker.LastName +
            " en el albarán de ventas " +
            albaran.SerieAlbaran + "-" + albaran.NumeroAlbaran +
            " (" + albaran.RazonSocial + " / Matrícula: " + albaran.Matricula + ").";

        setWorker(null);
        setTask(null);

        toast.info(message)
    };

    // Utility to parse a time string (e.g., "13:30") to a Date object for today
    const parseTime = (timeStr) => {
        const [hours, minutes] = timeStr.split(':').map(Number);
        const time = new Date();
        time.setHours(hours, minutes, 0, 0); // Resets seconds and milliseconds too
        return time;
    };

    // Checks if the current day is Saturday
    const isSaturday = () => {
        const today = new Date();
        return today.getDay() === 6; // getDay() returns 6 for Saturday
    };

    // Checks if the current time is within a threshold of a specified time setting
    const isTimeNear = (timeSetting, thresholdMinutes = 30) => {
        const now = new Date();
        const settingTime = parseTime(timeSetting);
        const diffMs = Math.abs(settingTime - now); // Difference in milliseconds
        return diffMs <= thresholdMinutes * 60 * 1000; // Convert minutes to milliseconds
    };

    const handleTaskFinished = (task) => {

        var albaran = task.CabeceraAlbaranCliente;
        var message = "El trabajador " +
            worker.FirstName + " " + worker.LastName +
            " ha terminado en el albarán de ventas " +
            albaran.SerieAlbaran + "-" + albaran.NumeroAlbaran +
            " (" + albaran.RazonSocial + " / Matrícula: " + albaran.Matricula + ").";

        toast.success(message);

        setTask(null);

        // Prepare settings map for easier access
        const settingsMap = new Map(settings.map(s => [s.Key, s.Value]));

        // Determine if special handling is required based on the day and time
        if (isSaturday()) {
            // Special handling for Saturday
            if (isTimeNear(settingsMap.get("DefaultEndTimeSaturday"))) {
                console.log("It's near end time on Saturday. Not reopening modal or autoselecting worker.");
                setWorker(null);
            }
        } else {
            // Handling for other days
            if (
                isTimeNear(settingsMap.get("DefaultLunchStartTime")) ||
                isTimeNear(settingsMap.get("DefaultEndTime")) ||
                isTimeNear(settingsMap.get("DefaultEndExtraTime"))
            ) {
                console.log("Near configured times on a weekday. Not reopening modal or autoselecting worker.");
                setWorker(null);
            }
        }
    };

    const handleBackClick = () => {
        if (viewType === 'projects') {
            if (project) {
                setProject(null);
            } else {
                setViewType('workers');
            }
        } else if (task) {
            setTask(null);
            setWorker(null);
        } else if (worker) {
            setWorker(null);
        }

    }

    return (
        <div>
            <WorkersContext.Provider value={[workers, setWorkers]}>
                <SettingsContext.Provider value={[settings, setSettings]}>
                    {
                        (worker || viewType === 'projects') ? (
                            <div className="flex flex-row justify-between">
                                <div className="flex items-center cursor-pointer mb-3" onClick={handleBackClick}>
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mr-2" fill="none"
                                         viewBox="0 0 24 24" stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                              d="M15 19l-7-7 7-7"/>
                                    </svg>
                                    <p className="text-xl sm:text-2xl">Atrás</p>
                                </div>
                                {
                                    worker ? (
                                        <div className="mr-4 mb-3 text-center text-xl sm:text-2xl">
                                            Trabajador:<br/><strong>{worker.FirstName} {worker.LastName}</strong>
                                        </div>
                                    ) : null
                                }
                            </div>
                        ) : null
                    }
                    <div
                        className="bg-white p-6 rounded-md shadow-md min-h-[250px] min-w-[300px] sm:min-w-[350px] max-w-[80vw] md:max-w-[650px] lg:max-w-[860px]">
                        {!token ? (
                            <LoginView onLogin={handleLogin} className="container"/>
                        ) : !worker ? (
                            <WorkerSelectionView onSelectWorker={handleWorkerSelection} role={role} viewType={viewType}
                                                 setViewType={setViewType} project={project} setProject={setProject}
                                                 workers={workers} setWorkers={setWorkers} className="container"/>
                        ) : (
                            <TaskDashboardView workerId={worker.UserID} task={task} role={role}
                                               onTaskStarted={handleTaskStarted} onFinish={handleTaskFinished}
                                               className="container"/>
                        )}

                        <ToastContainer
                            position="bottom-center"
                            autoClose={10000}
                            hideProgressBar={false}
                            newestOnTop
                            closeOnClick
                            rtl={false}
                            pauseOnFocusLoss
                            draggable
                            pauseOnHover
                            theme="light"
                            className="!text-2xl sm:!w-[90vw] lg:!w-auto mx-auto"
                        />
                    </div>
                    {localStorage?.getItem('token') != null && role && (role.toLowerCase() === "superadmin" || role.toLowerCase() === "admin") ? (
                        <div className="text-center mt-8 flex flex-row gap-3 justify-center">
                            <p onClick={handleLogout} className="text-lg cursor-pointer underline">Cerrar sesión.</p>
                            <p className="text-lg">Rol: {role}</p>
                        </div>
                    ) : null}
                </SettingsContext.Provider>
            </WorkersContext.Provider>
        </div>
    );
}

export default App;