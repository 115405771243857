import React, {useRef, useEffect} from 'react';
import {Checkbox, Label, Modal, TextInput} from "flowbite-react";

function AddUserModal({isAddUserModalOpen, setIsAddUserModalOpen, onAddUser}) {

    const firstNameRef = useRef('');
    const lastNameRef = useRef('');
    const articuloRef = useRef(null);
    const familiaRef = useRef(null);
    const plusOrgRef = useRef(false);
    const isActiveRef = useRef(true);

    if (!isAddUserModalOpen) {
        return null;
    }

    function resetAddUserModal() {
        setIsAddUserModalOpen(false);
        if (firstNameRef.current) firstNameRef.current.value = '';
        if (lastNameRef.current) lastNameRef.current.value = '';
        if (articuloRef.current) articuloRef.current.value = '';
        if (familiaRef.current) familiaRef.current.value = 'MO';
        if (plusOrgRef.current) plusOrgRef.current.checked = false;
        if (isActiveRef.current) isActiveRef.current.checked = true;
    }

    return (
        <Modal show={true} onClose={() => resetAddUserModal()} size="lg" position="center">
            <Modal.Header>Nuevo trabajador</Modal.Header>
            <Modal.Body>
                <form id="addUserModal">
                    <div className="flex flex-col gap-2">
                        <Label htmlFor="firstName" className="text-lg font-medium text-gray-700">
                            Nombre
                        </Label>
                        <TextInput type="text" id="firstName" className="w-full rounded-md" ref={firstNameRef}/>
                    </div>
                    <div className="flex flex-col gap-2 mt-4">
                        <Label htmlFor="lastName" className="text-lg text-gray-700">
                            Apellidos
                        </Label>
                        <TextInput type="text" id="lastName" className="w-full rounded-md" ref={lastNameRef}/>
                    </div>
                    <div className="flex flex-col gap-2 mt-4">
                        <Label htmlFor="articulo" className="text-lg text-gray-700">
                            Artículo
                        </Label>
                        <TextInput type="text" id="articulo" className="w-full rounded-md" ref={articuloRef}/>
                    </div>
                    <div className="flex flex-col gap-2 mt-4">
                        <Label htmlFor="familia" className="text-lg text-gray-700">
                            Familia
                        </Label>
                        <TextInput type="text" id="familia" className="w-full rounded-md" ref={familiaRef}/>
                    </div>
                    <div className="flex flex-row items-center gap-2 mt-6">
                        <Label htmlFor="plusOrg" className="text-lg text-gray-700">
                            ¿Plus Organizativo?
                        </Label>
                        <Checkbox id="plusOrg" className="h-6 w-6" ref={plusOrgRef}/>
                    </div>
                    <div className="flex flex-row items-center gap-2 mt-6">
                        <Label htmlFor="isActive" className="text-lg text-gray-700">
                            ¿Activo?
                        </Label>
                        <Checkbox id="isActive" defaultChecked className="h-6 w-6" ref={isActiveRef}/>
                    </div>
                </form>
            </Modal.Body>
            <Modal.Footer>
                <div className="flex w-full items-center justify-center">
                    <button onClick={() => {
                        onAddUser({
                            FirstName: firstNameRef.current.value,
                            LastName: lastNameRef.current.value,
                            PlusOrg: plusOrgRef.current.checked,
                            IsActive: isActiveRef.current.checked
                        });
                        resetAddUserModal();
                    }}
                            className="inline-flex text-lg justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-800 text-white hover:bg-blue-900 focus:outline-none sm:w-auto lg:text-xl"
                    >
                        Añadir trabajador
                    </button>
                </div>
            </Modal.Footer>
        </Modal>
    );
}

export default AddUserModal;