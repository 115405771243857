import api from './api';

export const postTask = async (task) => {
    try {
        const response = await api.post('/api/tasks', task);
        return response.data;
    } catch (error) {
        throw new Error('Error posting task: ' + error);
    }
};

export const deleteTask = async (id) => {
    try {
        const response = await api.delete(`/api/tasks/${id}`);
        return response.data;
    } catch (error) {
        throw new Error('Error deleting task: ' + error);
    }
};

export const getTasksByAlbaran = async (codigoEmpresa, ejercicioAlbaran, serieAlbaran, numeroAlbaran) => {
    try {
        const response = await api.get(`/api/tasks/${codigoEmpresa}/${ejercicioAlbaran}/${serieAlbaran}/${numeroAlbaran}`);
        return response.data;
    } catch (error) {
        throw new Error('Error fetching tasks by Albaran: ' + error);
    }
};

export const getTask = async (id) => {
    try {
        const response = await api.get(`/api/tasks/${id}`);
        return response.data;
    } catch (error) {
        throw new Error('Error fetching task: ' + error);
    }
};

export const disableTask = async (id) => {
    try {
        const response = await api.put(`/api/tasks/${id}`);
        return response.data;
    } catch (error) {
        throw new Error('Error disabling task: ' + error);
    }
};

export const disableTasksByAlbaran = async (codigoEmpresa, ejercicioAlbaran, serieAlbaran, numeroAlbaran) => {
    try {
        const response = await api.put(`/api/tasks/${codigoEmpresa}/${ejercicioAlbaran}/${serieAlbaran}/${numeroAlbaran}`);
        return response.data;
    } catch (error) {
        throw new Error('Error disabling tasks by Albaran: ' + error);
    }
};