import React, {useRef, useEffect} from 'react';
import {Label, Modal, TextInput, Select} from "flowbite-react";
import dayjs from 'dayjs';

function EditAbsenceModal({selectedAbsence, onEditAbsence, onClose}) {
    const startDateRef = useRef(null);
    const startTimeRef = useRef(null);
    const endDateRef = useRef(null);
    const endTimeRef = useRef(null);
    const typeRef = useRef(null);

    const {Id, StartDate, EndDate, Type} = selectedAbsence || {};

    useEffect(() => {
        if (startDateRef.current && StartDate) {
            const date = dayjs(StartDate);
            startDateRef.current.value = date.format('YYYY-MM-DD');
            startTimeRef.current.value = date.format('HH:mm');
        }
        if (endDateRef.current && EndDate) {
            const date = dayjs(EndDate);
            endDateRef.current.value = date.format('YYYY-MM-DD');
            endTimeRef.current.value = date.format('HH:mm');
        }
        if (typeRef.current) typeRef.current.value = Type || '';
    }, [StartDate, EndDate, Type]);

    const handleSubmit = () => {
        const startDate = startDateRef.current.value;
        const startTime = startTimeRef.current.value || '00:00';
        const endDate = endDateRef.current.value;
        const endTime = endTimeRef.current.value || '23:59';

        const updatedStartDate = dayjs(`${startDate}T${startTime}`).toISOString();
        const updatedEndDate = endDate ? dayjs(`${endDate}T${endTime}`).toISOString() : null;

        onEditAbsence(Id, {
            startDate: updatedStartDate,
            endDate: updatedEndDate,
            type: typeRef.current.value
        });
        onClose();
    };

    return (
        <Modal show={!!selectedAbsence} onClose={onClose} size="lg" position="center">
            <Modal.Header>Editando ausencia</Modal.Header>
            <Modal.Body>
                <div className="flex flex-col gap-4 mt-2">
                    <Label htmlFor="StartDate">
                        Fecha inicio
                    </Label>
                    <div className="flex gap-2">
                        <TextInput type="date" id="StartDate" className="w-full rounded-md" ref={startDateRef}/>
                        <TextInput type="time" id="StartTime" className="w-full rounded-md" ref={startTimeRef}/>
                    </div>
                </div>
                <div className="flex flex-col gap-4 mt-2">
                    <Label htmlFor="EndDate">
                        Fecha finalización
                    </Label>
                    <div className="flex gap-2">
                        <TextInput type="date" id="EndDate" className="w-full rounded-md" ref={endDateRef}/>
                        <TextInput type="time" id="EndTime" className="w-full rounded-md" ref={endTimeRef}/>
                    </div>
                </div>
                <div className="flex flex-col gap-4 mt-2">
                    <Label htmlFor="Type">
                        Tipo de ausencia
                    </Label>
                    <Select id="Type" className="w-full rounded-md" ref={typeRef}>
                        <option value="Vacaciones">Vacaciones</option>
                        <option value="Baja">Baja</option>
                        <option value="Medico">Médico</option>
                        <option value="Permiso">Permiso</option>
                        <option value="Excedencia">Excedencia</option>
                        <option value="Otros_Asuntos">Otros Asuntos</option>
                    </Select>
                </div>
            </Modal.Body>

            <Modal.Footer>
                <button onClick={handleSubmit}
                        className="inline-flex text-lg justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-800 text-white hover:bg-blue-900 focus:outline-none sm:w-auto lg:text-xl"
                >
                    Confirmar cambios
                </button>
            </Modal.Footer>
        </Modal>
    );
}

export default EditAbsenceModal;