import React from 'react';
import Pagination from '../common/Pagination';
import {formatDate, formatDate1, formatElapsedTimeHM, getButtonColor} from "../../services/utils";

const ProjectList = ({ projects, onSelectProject, currentPage, totalItems, pageSize, onPageChange, ejercicio, serie, numero, onEjercicioChange, onSerieChange, onNumeroChange }) => {
    const currentYear = new Date().getFullYear();
    const ejercicioOptions = Array.from({ length: 7 }, (_, i) => currentYear - i);
    const seriesOptions = ["B", "CH", "FA", "OE", "OT", "RF", "T", "RC"];

    return (
        <div>
            <div>
                <h2 className="text-2xl font-medium">Albaranes disponibles</h2>

            </div>
            <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center sm:justify-start gap-5 mt-8">
                {/* Ejercicio Dropdown */}
                <div className="flex flex-row sm:flex-col gap-5 sm:gap-3 items-center">
                    <label htmlFor="ejercicio" className="sm:w-16 flex-shrink-0">Ejercicio</label>
                    <select
                        className="border border-gray-300 rounded-md w-full"
                        id="ejercicio"
                        name="ejercicio"
                        value={ejercicio}
                        onChange={(event) => onEjercicioChange(event.target.value)}
                    >
                        {ejercicioOptions.map((year) => (
                            <option key={year} value={year}>
                                {year}
                            </option>
                        ))}
                    </select>
                </div>

                {/* Serie Dropdown */}
                <div className="flex flex-row sm:flex-col gap-5 sm:gap-3 items-center">
                    <label htmlFor="serie" className="sm:w-16 flex-shrink-0">Serie</label>
                    <select
                        className="border border-gray-300 rounded-md w-full"
                        id="serie"
                        name="serie"
                        value={serie}
                        onChange={(event) => onSerieChange(event.target.value)}
                    >
                        <option value="">Todas</option>
                        {seriesOptions.map((option) => (
                            <option key={option} value={option}>
                                {option}
                            </option>
                        ))}
                    </select>
                </div>

                {/* Numero Input */}
                <div className="flex flex-row sm:flex-col gap-5 sm:gap-3 items-center">
                    <label htmlFor="numero" className="sm:w-16 flex-shrink-0">Número</label>
                    <input
                        className="border border-gray-300 rounded-md w-full max-w-[7rem] sm:max-w-none"
                        type="text"
                        id="numero"
                        name="numero"
                        value={numero}
                        onChange={(event) => onNumeroChange(event.target.value)}
                    />
                </div>
            </div>
            <Pagination
                currentPage={currentPage}
                totalItems={totalItems}
                pageSize={pageSize}
                onPageChange={onPageChange}
            />
            <div className="grid grid-cols-1 auto-rows-max w-full auto-cols-max gap-5 sm:grid-cols-2 md:grid-cols-3">
                {projects && Array.isArray(projects) && projects.length > 0 ?
                    (projects.map((project, index) => (
                        <div
                            key={project.EjercicioAlbaran + project.SerieAlbaran + project.NumeroAlbaran}
                            className="flex flex-col w-full"
                        >
                            <button
                                className={`${getButtonColor(project.SerieAlbaran)} brand flex align-middle items-center justify-center text-white font-bold !text-lg leading-relaxed py-2 px-4 w-full h-full text-center`}
                                onClick={() => onSelectProject(project)}
                            >
                                <span>
                                    {project.SerieAlbaran}-{project.NumeroAlbaran}
                                    <br />{project.RazonSocial}
                                    {project.Matricula && (
                                        <>
                                            <br />{project.Matricula}
                                        </>
                                    )}
                                    {false && (
                                        <>
                                            <br/><br/>
                                            "añadir nº de tasks"
                                        </>
                                    )}
                                    <br/><br/>{project.FechaAlbaran && formatDate(project.FechaAlbaran)}
                                </span>
                            </button>
                        </div>
                    ))
                ) : (
                    <div className="col-span-full mt-8 text-center">
                        <p className="text-xl font-bold">No se han encontrado resultados.</p>
                    </div>
                )}
            </div>
            <Pagination
                currentPage={currentPage}
                totalItems={totalItems}
                pageSize={pageSize}
                onPageChange={onPageChange}
            />
        </div>
    );
};

export default ProjectList;