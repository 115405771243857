import React, {useState, useEffect, useRef, useContext} from 'react';
import {Modal, Label, TextInput} from 'flowbite-react';
import {getSettings, pushSettings} from '../../services/settingsService';
import {SettingsContext} from '../../App';
import {toast} from "react-toastify";

const SettingsModal = ({isOpen, setIsOpen}) => {
    const [settings, setSettings] = useContext(SettingsContext);
    const [originalSettings, setOriginalSettings] = useState([]);
    const formRef = useRef(null);

    useEffect(() => {
        const fetchSettings = async () => {
            const fetchedSettings = await getSettings();
            setSettings(fetchedSettings);
            setOriginalSettings(JSON.parse(JSON.stringify(fetchedSettings))); // Deep copy to avoid direct mutation
        };

        fetchSettings();
    }, []);

    const handleSubmit = async (event) => {
        event.preventDefault();

        // Assuming your settings have unique IDs or keys
        const formElements = formRef.current.elements;
        const newSettings = settings.map((setting, index) => {
            return {...setting, Value: formElements[index].value};
        });

        const changedSettings = newSettings.filter((setting, index) =>
            setting.Value !== originalSettings[index].Value
        );

        if (changedSettings.length > 0) {
            await pushSettings(changedSettings);

            setSettings(newSettings);

            //Toast notification
            toast.success('Ajustes guardados correctamente');
        }

        setIsOpen(false);
    };

    return (
        <Modal show={isOpen} onClose={() => setIsOpen(false)}>
            <Modal.Header>Ajustes</Modal.Header>
            <Modal.Body>
                <form ref={formRef} onSubmit={handleSubmit} className="space-y-4" id="settingsForm">
                    {settings.map((setting, index) => (
                        <div key={setting.Key} className="flex flex-col">
                            <Label htmlFor={setting.Key} className="font-semibold">{setting.Key}</Label>
                            <TextInput id={setting.Key} type="text" defaultValue={setting.Value} className="mt-2"/>
                        </div>
                    ))}
                </form>
            </Modal.Body>
            <Modal.Footer>
                <div className="flex w-full items-center justify-center">
                    <button type="submit" form="settingsForm"
                            className="inline-flex text-lg justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-800 text-white hover:bg-blue-900 focus:outline-none sm:w-auto lg:text-xl">
                        Guardar
                    </button>
                </div>
            </Modal.Footer>
        </Modal>
    );
};

export default SettingsModal;