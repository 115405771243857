import React, {useState, useEffect} from 'react';
import {getAbsencesByUserId, deleteAbsence, createAbsence, updateAbsence} from '../../services/absenceService';
import {toast} from "react-toastify";
import {getButtonColorAbsences, formatDate1} from "../../services/utils";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import {esES} from '@mui/x-date-pickers/locales';
import dayjs from "dayjs";
import 'dayjs/locale/es';
import AddAbsenceModal from './AddAbsenceModal';
import EditAbsenceModal from './EditAbsenceModal';
import {calculateWorkDays} from '../../services/workDaysCalculator';

const Absences = ({workerId, role}) => {
    const [absences, setAbsences] = useState([]);
    const [startTime, setStartTime] = useState(dayjs().startOf('month'));
    const [endTime, setEndTime] = useState(dayjs().endOf('month'));
    const [isAddModalOpen, setIsAddModalOpen] = useState(false);
    const [selectedAbsence, setSelectedAbsence] = useState(null);

    const fetchAbsences = async () => {
        try {
            let fetchedAbsences = await getAbsencesByUserId(workerId, startTime.toISOString(), endTime.toISOString());

            if (!Array.isArray(fetchedAbsences)) {
                console.error('getAbsencesByUserId did not return an array:', fetchedAbsences);
                fetchedAbsences = [];
            }

            // Sort absences by StartDate from newer to older
            fetchedAbsences = fetchedAbsences.sort((a, b) => new Date(b.StartDate) - new Date(a.StartDate));

            setAbsences(fetchedAbsences);
        } catch (error) {
            toast.error('Error al cargar las ausencias, avisa a un administrador: ' + error.message);
        }
    };

    useEffect(() => {
        fetchAbsences();
    }, [workerId, startTime, endTime]);

    const handleRemoveClick = (absenceId) => {
        toast(({closeToast}) =>
                <div>
                    <p>¿Seguro que quieres eliminar esta ausencia?
                        <button className="ml-7 text-white !bg-green-700"
                                onClick={() => {
                                    deleteAbsence(absenceId)
                                        .then(() => {
                                            setAbsences(absences.filter(absence => absence.Id !== absenceId));
                                            toast.success("Ausencia eliminada correctamente");
                                        })
                                        .catch((error) => {
                                            toast.error("Error al eliminar la ausencia: " + error);
                                        })
                                        .finally(() => {
                                            closeToast();
                                        })
                                }}
                        >
                            Confirmar
                        </button>
                    </p>
                </div>,
            {type: toast.TYPE.INFO}
        )
    };

    const handleAddNewAbsence = () => {
        setIsAddModalOpen(true);
    };

    const handleAddAbsence = async (absenceData) => {
        try {
            const newAbsence = await createAbsence({
                ...absenceData,
                userId: workerId,
            });
            setAbsences([...absences, newAbsence]);
            toast.success("Ausencia añadida correctamente");
        } catch (error) {
            toast.error("Error al añadir la ausencia: " + error);
        }
    };

    const handleEditClick = (absence) => {
        setSelectedAbsence(absence);
    };

    const handleEditAbsence = async (id, absenceData) => {
        try {
            const updatedAbsence = await updateAbsence(id, absenceData);
            setAbsences(absences.map(absence => absence.Id === id ? updatedAbsence : absence));
            toast.success("Ausencia actualizada correctamente");
        } catch (error) {
            toast.error("Error al actualizar la ausencia: " + error);
        }
    };

    return (
        <div>
            <div className="mb-5 flex flex-row gap-2 justify-between items-end">
                <div>
                    <label className="flex flex-col gap-2">
                        <p className="mr-3 inline text-xl sm:text-2xl">Periodo:</p>
                    </label>
                </div>
                <button
                    className="text-2xl sm:text-2xl !bg-blue-800 md:hover:!bg-blue-900 text-white font-bold py-2 px-4 rounded max-h-[60px] my-auto"
                    onClick={handleAddNewAbsence}
                >
                    Añadir nueva ausencia
                </button>
            </div>
            <div className="flex flex-row gap-3 items-center mb-8">
                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es"
                                      localeText={esES.components.MuiLocalizationProvider.defaultProps.localeText}>
                    <DatePicker value={startTime} onChange={(newValue) => setStartTime(newValue)} className="inline"
                                inputFormat="DD/MM/YYYY"/>
                </LocalizationProvider>
                <p className="inline text-xl"> a </p>
                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es"
                                      localeText={esES.components.MuiLocalizationProvider.defaultProps.localeText}>
                    <DatePicker value={endTime} onChange={(newValue) => setEndTime(newValue)} className="inline"
                                inputFormat="DD/MM/YYYY"/>
                </LocalizationProvider>
            </div>
            <div className="grid grid-cols-1 auto-rows-max auto-cols-max gap-5 sm:grid-cols-2 md:grid-cols-3">
                {absences && Array.isArray(absences) && absences.length > 0 ? (
                    absences.map((absence) => (
                        <div
                            key={absence.Id}
                            className="flex flex-col"
                        >
                            {role && role.toLowerCase() === "superadmin" ? (
                                <div
                                    className="w-full bg-gray-600 flex justify-end items-center p-1.5 rounded-t rounded-b-none">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="h-6 w-6 text-white cursor-pointer mr-2"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor"
                                        onClick={() => handleEditClick(absence)}
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth={2}
                                            d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
                                        />
                                    </svg>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="h-6 w-6 text-white cursor-pointer"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor"
                                        onClick={() => handleRemoveClick(absence.Id)}
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth={2}
                                            d="M6 18L18 6M6 6l12 12"
                                        />
                                    </svg>
                                </div>
                            ) : null}
                            <div
                                className={`${getButtonColorAbsences(absence.Type)} brand text-white font-bold !text-lg leading-relaxed py-2 px-4 w-full h-full text-center ${role && role.toLowerCase() === "superadmin" ? 'rounded-b rounded-t-none -mt-1' : 'rounded'}`}>
                                {absence.Type}<br/><br/>
                                {formatDate1(absence.StartDate)}<br/>
                                {absence.EndDate ? formatDate1(absence.EndDate) : "En curso"}<br/><br/>
                                {calculateWorkDays(absence.StartDate, absence.EndDate) < 1
                                    ? "< 1 Día Laborable"
                                    : `${calculateWorkDays(absence.StartDate, absence.EndDate)} Días Laborables`}
                            </div>
                        </div>
                    ))
                ) : (
                    <div className="text-center text-gray-600 col-span-full mt-4">
                        No se han encontrado ausencias para este usuario en este periodo
                    </div>
                )}
            </div>
            <AddAbsenceModal
                isOpen={isAddModalOpen}
                onClose={() => setIsAddModalOpen(false)}
                onAddAbsence={handleAddAbsence}
            />
            <EditAbsenceModal
                selectedAbsence={selectedAbsence}
                onClose={() => setSelectedAbsence(null)}
                onEditAbsence={handleEditAbsence}
            />
        </div>
    );
};

export default Absences;