import React, {useState} from 'react';
import TaskDashboard from '../../components/TaskDashboard/TaskDashboard';
import TaskHistory from '../../components/TaskHistory/TaskHistory';
import TaskCompletion from "../../components/TaskCompletion/TaskCompletion";
import Absences from "../../components/Absences/Absences"; // Import the new Absences component

const TaskDashboardView = ({workerId, task, role, onTaskStarted, onFinish}) => {

    const [view, setView] = useState('dashboard');

    const handleProjectSelection = (task) => {
        onTaskStarted(task);
    };

    const handleViewChange = (event) => {
        setView(event.target.value);
    };

    return (
        <div>
            <h1>Gestión de tareas ></h1>
            <select value={view} onChange={handleViewChange} className="text-2xl sm:text-3xl font-medium mb-8">
                {task ? (
                    <option value="dashboard" className="text-xl">Finalizar Tarea</option>
                ) : (
                    <option value="dashboard" className="text-xl">Selecciona Albaran</option>
                )}
                {role && (role.toLowerCase() === "superadmin" || role.toLowerCase() === "admin") && (
                    <>
                        <option value="history" className="text-xl">Historial de Tareas</option>
                        <option value="absences" className="text-xl">Ausencias</option>
                    </>
                )}
            </select>
            {task && view === 'dashboard' ? (
                <TaskCompletion task={task} onFinish={onFinish}/>
            ) : view === 'dashboard' ? (
                <TaskDashboard workerId={workerId} onSelectProject={handleProjectSelection}/>
            ) : view === 'history' ? (
                <TaskHistory workerId={workerId} role={role}/>
            ) : view === 'absences' ? (
                <Absences workerId={workerId} role={role}/>
            ) : null}
        </div>
    );
};

export default TaskDashboardView;