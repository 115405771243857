import React, {useEffect, useState} from 'react';
import {finishTask, finishTaskWithComment, removeTask} from "../../services/taskService";
import {formatElapsedTime, getButtonColor} from "../../services/utils";
import {toast} from "react-toastify";
import {getTasksByAlbaran} from "../../services/tasks2Service";
import {Checkbox, Label} from "flowbite-react";
import {postSubTimeEntry} from "../../services/subTimeEntriesService";

// Define a dictionary of options for each SerieAlbaran
const optionsDict = {
    "T": [
        'SELECCIONA UNA OPCIÓN:',
        'MANTENIMIENTO TALLER',
        'DESCARGA MERCANCÍA',
        'SALIDA MATERIAL',
        'SALIDA DIRECCIÓN',
        'USO FURGONETA',
        'SERVICIO ALMACÉN',
    ],
    // Add more SerieAlbaran types with their own options
    // "S": ['option1', 'option2'],
};

const TaskCompletion = ({task, onFinish, onReview}) => {

    const [elapsedTime, setElapsedTime] = useState(Date.now() - new Date(task.StartTime).getTime());
    const [selectedOption, setSelectedOption] = useState(optionsDict[task.SerieAlbaran] ? optionsDict[task.SerieAlbaran][0] : '');

    const [projectTasks, setProjectTasks] = useState([]);
    const [selectedProjectTasks, setSelectedProjectTasks] = useState([]);

    useEffect(() => {
        const fetchProjectTasks = async () => {
            const tasks = await getTasksByAlbaran(task.CodigoEmpresa, task.EjercicioAlbaran, task.SerieAlbaran, task.NumeroAlbaran);
            setProjectTasks(tasks);
        };

        fetchProjectTasks();
    }, [task]);

    useEffect(() => {
        setSelectedOption(optionsDict[task.SerieAlbaran] ? optionsDict[task.SerieAlbaran][0] : '');

        const interval = setInterval(() => {
            setElapsedTime(Date.now() - new Date(task.StartTime).getTime());
        }, 1000);

        return () => clearInterval(interval);
    }, [task]);

    const flattenOptions = (options) => {
        const flattened = [];

        for (let key in options) {
            const value = options[key];

            // If the value is an array, prefix each item with the key
            if (Array.isArray(value)) {
                value.forEach((item) => {
                    flattened.push(`${key}: ${item}`);
                });
            }
            // If the value is an object, recursively flatten it
            else if (typeof value === 'object') {
                const nestedOptions = flattenOptions(value);

                nestedOptions.forEach((opt) => {
                    flattened.push(`${key}: ${opt}`);
                });
            }
            // Otherwise, the value is a simple string - just add it directly
            else {
                flattened.push(value);
            }
        }

        return flattened;
    };

    const handleTaskChange = (event) => {
        const {id, checked} = event.target;
        setSelectedProjectTasks(prevTasks => {
            if (checked) {
                return [...prevTasks, id];
            } else {
                return prevTasks.filter(task => task !== id);
            }
        });
    };

    const handleFinishClick = async (task) => {
        try {
            const elapsedTime = Date.now() - new Date(task.StartTime).getTime();
            const defaultOption = optionsDict[task.SerieAlbaran] ? optionsDict[task.SerieAlbaran][0] : '';

            // Check if the elapsed time is less than 3 minutes
            if (elapsedTime < 180000 && process.env.NODE_ENV !== 'development') {
                // Remove the TimeEntry
                await removeTask(task.TimeEntryID);
                // Remove the task from the UI
                onFinish(task);
            } else {
                if (projectTasks && projectTasks.length > 0) {
                    if (selectedProjectTasks.length === 0) {
                        toast.error('Por favor, selecciona un comentario.');
                        return;
                    }
                    for (const taskId of selectedProjectTasks) {
                        const newSubTimeEntry = {
                            TaskID: taskId,
                            TimeEntryID: task.TimeEntryID
                        };
                        await postSubTimeEntry(newSubTimeEntry);
                    }
                    await finishTask(task.TimeEntryID);
                }
                // Check if optionsDict contains the SerieAlbaran and if the selected option is not the default one
                else if (optionsDict[task.SerieAlbaran] && selectedOption !== '' && selectedOption !== defaultOption) {
                    await finishTaskWithComment(task.TimeEntryID, selectedOption);
                } else if (optionsDict[task.SerieAlbaran] && (selectedOption === '' || selectedOption === defaultOption)) {
                    // If there are options but the selected one is the default, cancel the action and tell him to select a comment
                    toast.error('Por favor, selecciona un comentario.');
                    return;
                } else {
                    await finishTask(task.TimeEntryID);
                }
                onFinish(task);
            }
        } catch (error) {
            toast.error('Error al terminar tarea: ' + error);
            console.error('Error finishing task:', error);
        }
    };

    var albaran = task.CabeceraAlbaranCliente;

    return (
        <div>
            <div className="flex flex-col gap-2 mb-8">
                <p className="text-xl sm:text-2xl">Tarea actual:</p>
                <button
                    className={`${getButtonColor(task.SerieAlbaran)} brand text-white font-bold leading-relaxed py-2 px-4 rounded w-full h-full mb-3`}
                >
                    {task.SerieAlbaran}-{task.NumeroAlbaran}<br/>
                    {albaran.RazonSocial}<br/>
                    {albaran.Matricula}
                </button>
                <p className="text-xl sm:text-2xl">Tiempo empleado:</p>
                <button
                    className="text-lg sm:text-xl text-black !bg-gray-100 rounded-3xl py-1.5 px-3 w-fit">{formatElapsedTime(elapsedTime)}</button>
                {projectTasks && projectTasks.length > 0 && (
                    <div>
                        <p className="text-xl sm:text-2xl mt-3 mb-2">Tareas realizadas:</p>
                        {projectTasks.map(ptask => (
                            <div key={ptask.Name} className="flex text-xl items-center gap-2">
                                <Checkbox className="w-6 h-6" id={ptask.TaskID} onChange={handleTaskChange}/>
                                <Label className="text-xl" htmlFor={ptask.TaskID}>{ptask.Name}</Label>
                            </div>
                        ))}
                    </div>
                )}
                {optionsDict[task.SerieAlbaran] && (
                    <div>
                        <p className="text-xl sm:text-2xl mt-3 mb-2">Comentario:</p>
                        <select
                            className="text-md sm:text-lg text-black !bg-gray-100 rounded-3xl py-1.5 px-3 max-w-full whitespace-normal"
                            value={selectedOption} onChange={e => setSelectedOption(e.target.value)}>
                            {flattenOptions(optionsDict[task.SerieAlbaran]).map(option => (
                                <option className="text-md sm:text-lg max-w-[100vw] whitespace-normal" value={option}
                                        key={option}>
                                    {option}
                                </option>
                            ))}
                        </select>
                    </div>
                )}
            </div>
            <div className="flex flex-row gap-4">
                <button className="brand" onClick={() => handleFinishClick(task)}>Terminar</button>
                <button className="hidden" onClick={() => onReview(task)}>Enviar a review</button>
            </div>
        </div>
    );
};

export default TaskCompletion;