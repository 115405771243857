import React, {useRef, useEffect} from 'react';
import {Label, Modal, TextInput, Select} from "flowbite-react";
import dayjs from 'dayjs';

function AddAbsenceModal({isOpen, onClose, onAddAbsence}) {
    const startDateRef = useRef(null);
    const startTimeRef = useRef(null);
    const endDateRef = useRef(null);
    const endTimeRef = useRef(null);
    const typeRef = useRef(null);

    // Set default values when the modal opens
    useEffect(() => {
        if (isOpen) {
            const now = dayjs();
            if (startDateRef.current) startDateRef.current.value = now.format('YYYY-MM-DD');
            if (startTimeRef.current) startTimeRef.current.value = now.format('HH:mm');
            if (endDateRef.current) endDateRef.current.value = now.format('YYYY-MM-DD');
            if (endTimeRef.current) endTimeRef.current.value = '23:59';
        } else {
            if (startDateRef.current) startDateRef.current.value = '';
            if (startTimeRef.current) startTimeRef.current.value = '';
            if (endDateRef.current) endDateRef.current.value = '';
            if (endTimeRef.current) endTimeRef.current.value = '';
        }
    }, [isOpen]);

    const handleSubmit = () => {
        const startDate = startDateRef.current?.value;
        const startTime = startTimeRef.current?.value || '00:00';
        const endDate = endDateRef.current?.value;
        const endTime = endTimeRef.current?.value || '23:59';

        const StartDate = dayjs(`${startDate}T${startTime}`).toISOString();
        const EndDate = endDate ? dayjs(`${endDate}T${endTime}`).toISOString() : null;

        console.log(StartDate, EndDate, typeRef.current?.value);

        const absenceData = {
            startDate: StartDate,
            endDate: EndDate,
            type: typeRef.current?.value
        };

        onAddAbsence(absenceData);
        onClose();
    };

    return (
        <Modal show={isOpen} onClose={onClose} size="lg" position="center">
            <Modal.Header>Añadir nueva ausencia</Modal.Header>
            <Modal.Body>
                <div className="flex flex-col gap-4 mt-2">
                    <Label htmlFor="StartDate">
                        Fecha inicio
                    </Label>
                    <div className="flex gap-2">
                        <TextInput type="date" id="StartDate" className="w-full rounded-md" ref={startDateRef}/>
                        <TextInput type="time" id="StartTime" className="w-full rounded-md" ref={startTimeRef}/>
                    </div>
                </div>
                <div className="flex flex-col gap-4 mt-2">
                    <Label htmlFor="EndDate">
                        Fecha finalización
                    </Label>
                    <div className="flex gap-2">
                        <TextInput type="date" id="EndDate" className="w-full rounded-md" ref={endDateRef}/>
                        <TextInput type="time" id="EndTime" className="w-full rounded-md" ref={endTimeRef}/>
                    </div>
                </div>
                <div className="flex flex-col gap-4 mt-2">
                    <Label htmlFor="Type">
                        Tipo de ausencia
                    </Label>
                    <Select id="Type" className="w-full rounded-md" ref={typeRef}>
                        <option value="Vacaciones">Vacaciones</option>
                        <option value="Baja">Baja</option>
                        <option value="Medico">Médico</option>
                        <option value="Permiso">Permiso</option>
                        <option value="Excedencia">Excedencia</option>
                        <option value="Otros_Asuntos">Otros Asuntos</option>
                    </Select>
                </div>
            </Modal.Body>

            <Modal.Footer>
                <button onClick={handleSubmit}
                        className="inline-flex text-lg justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-800 text-white hover:bg-blue-900 focus:outline-none sm:w-auto lg:text-xl"
                >
                    Añadir ausencia
                </button>
            </Modal.Footer>
        </Modal>
    );
}

export default AddAbsenceModal;