import dayjs from 'dayjs';
import Holidays from 'date-holidays';

const hd = new Holidays();
hd.init('ES', 'MA'); // ES for Spain, MA for Madrid

export function calculateWorkDays(startDate, endDate) {
    let start = dayjs(startDate);
    let end = endDate ? dayjs(endDate) : dayjs();
    let days = 0;

    while (start.isBefore(end) || start.isSame(end, 'day')) {
        const currentDate = start.format('YYYY-MM-DD');
        const holiday = hd.isHoliday(new Date(currentDate));

        if (
            start.day() !== 0 && // Sunday
            start.day() !== 6 && // Saturday
            !holiday
        ) {
            days++;
        }
        start = start.add(1, 'day');
    }

    return days;
}