import api from './api';

export const getUsers = async () => {
    try {
        const response = await api.get('/api/users');
        return response.data;
    } catch (error) {
        throw new Error('Error fetching users: ' + error);
    }
};

export const getInactiveUsers = async () => {
    try {
        const response = await api.get('/api/users/softdeleted');
        return response.data;
    } catch (error) {
        throw new Error('Error fetching users: ' + error);
    }
};

export const addUser = async (user) => {
    try {
        const response = await api.post('/api/users', user);
        return response.data;
    } catch (error) {
        throw new Error('Error adding user: ' + error);
    }
};

export const updateUser = async (id, user) => {
    try {
        const response = await api.put(`/api/users/${id}`, user);
        return response.data;
    } catch (error) {
        throw new Error('Error updating user: ' + error);
    }
};

export const deleteUser = async (id) => {
    try {
        const response = await api.delete(`/api/users/${id}`);
        return response.data;
    } catch (error) {
        throw new Error('Error deleting user: ' + error);
    }
};

export const softdeleteUser = async (id) => {
    try {
        const response = await api.delete(`/api/users/${id}/soft`);
        return response.data;
    } catch (error) {
        throw new Error('Error deleting user: ' + error);
    }
};