import React, {useState} from 'react';
import {Button, Modal} from "flowbite-react";
import EditUserModal from "./EditUserModal";
import AddUserModal from "./AddUserModal";

const WorkerList = ({ workers, role, onSelectWorker, onAddUser, onEditUser, onDeleteUser, onSoftdeleteUser, onToggleInactiveUsers, showingInactiveUsers }) => {
    const colors = [
        '!bg-blue-800',
        '!bg-red-800',
        '!bg-yellow-600',
        '!bg-gray-700',
        '!bg-lime-700',
        '!bg-teal-700',
        '!bg-purple-700',
        '!bg-green-800',
        '!bg-fuchsia-900',
        '!bg-sky-800',
    ];

    const [isAddUserModalOpen, setIsAddUserModalOpen] = useState(false);
    const [confirmDeleteUser, setConfirmDeleteUser] = useState(null);
    const [selectedUser, setSelectedUser] = useState(null);

    function getWorkerColor(userID) {
        let order = 0;
        for(let i = 0; i < workers.length; i++) {
            if(workers[i].UserID===userID) order = i;
        }
        return colors[(order % colors.length)];
    }

    function confirmDelete() {

        if(!confirmDeleteUser) return;

        let worker = confirmDeleteUser;

        return (
            <Modal show={true} onClose={() => setConfirmDeleteUser(null)} popup size="lg" position="center">
                <Modal.Header />
                <Modal.Body>
                    <div className="text-center">
                        <div className="mx-auto mb-5 h-16 w-16 rounded-full bg-gray-200 flex items-center justify-center">
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8 mr-1 text-gray-700" viewBox="0 -5 32 32" version="1.1">
                                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                    <g id="Icon-Set-Filled" transform="translate(-518.000000, -1146.000000)" fill="currentColor">
                                        <path d="M540.647,1159.24 C541.039,1159.63 541.039,1160.27 540.647,1160.66 C540.257,1161.05 539.623,1161.05 539.232,1160.66 L536.993,1158.42 L534.725,1160.69 C534.331,1161.08 533.692,1161.08 533.298,1160.69 C532.904,1160.29 532.904,1159.65 533.298,1159.26 L535.566,1156.99 L533.327,1154.76 C532.936,1154.37 532.936,1153.73 533.327,1153.34 C533.718,1152.95 534.352,1152.95 534.742,1153.34 L536.981,1155.58 L539.281,1153.28 C539.676,1152.89 540.314,1152.89 540.708,1153.28 C541.103,1153.68 541.103,1154.31 540.708,1154.71 L538.408,1157.01 L540.647,1159.24 L540.647,1159.24 Z M545.996,1146 L528.051,1146 C527.771,1145.98 527.485,1146.07 527.271,1146.28 L518.285,1156.22 C518.074,1156.43 517.983,1156.71 517.998,1156.98 C517.983,1157.26 518.074,1157.54 518.285,1157.75 L527.271,1167.69 C527.467,1167.88 527.723,1167.98 527.979,1167.98 L527.979,1168 L545.996,1168 C548.207,1168 550,1166.21 550,1164 L550,1150 C550,1147.79 548.207,1146 545.996,1146 L545.996,1146 Z" id="delete">
                                        </path>
                                    </g>
                                </g>
                            </svg>
                        </div>
                        <h3 className="mb-5 text-lg mx-auto max-w-[80%] font-normal text-gray-500 dark:text-gray-400">
                            ¿Estás seguro de que quieres {showingInactiveUsers ? "eliminar permanentemente" : "desactivar"} al usuario {worker.FirstName} {worker.LastName}?
                        </h3>
                        <div className="flex justify-center gap-4">
                            <Button size="lg" color="failure" onClick={() => (showingInactiveUsers ? onDeleteUser(worker.UserID) : onSoftdeleteUser(worker.UserID)) && setConfirmDeleteUser(null)}>
                                Sí, estoy seguro
                            </Button>
                            <Button size="lg" color="gray" onClick={() => setConfirmDeleteUser(null)}>
                                No, cancelar
                            </Button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        );
    }

    return (
        <div>
            <div className={`${role && role.toLowerCase() === "superadmin" ? 'flex flex-row gap-3 justify-between items-center' : ''}`}>
                {role && role.toLowerCase() === "superadmin" ? (
                    <>
                        <h2 className="text-2xl font-medium">{showingInactiveUsers ? "Usuarios desactivados" : "Usuarios activos"}</h2>
                        <div className="flex flex-row gap-2">
                            <button
                                className={`${showingInactiveUsers ? "!bg-red-800 md:hover:!bg-red-950" : "!bg-blue-800 md:hover:!bg-blue-950"} text-2xl sm:text-2xl text-white font-bold py-2 px-2.5 rounded max-h-[60px] flex items-center`}
                                onClick={() => onToggleInactiveUsers()}
                            >
                                <svg xmlns="http://www.w3.org/2000/svg" className="inline mb-0.5 w-[20px] h-[20px] text-white" viewBox="0 1 41.336 41.336">
                                    <g>
                                        <path fill="currentColor" d="M36.335,5.668h-8.167V1.5c0-0.828-0.672-1.5-1.5-1.5h-12c-0.828,0-1.5,0.672-1.5,1.5v4.168H5.001c-1.104,0-2,0.896-2,2   s0.896,2,2,2h2.001v29.168c0,1.381,1.119,2.5,2.5,2.5h22.332c1.381,0,2.5-1.119,2.5-2.5V9.668h2.001c1.104,0,2-0.896,2-2   S37.438,5.668,36.335,5.668z M14.168,35.67c0,0.828-0.672,1.5-1.5,1.5s-1.5-0.672-1.5-1.5v-21c0-0.828,0.672-1.5,1.5-1.5   s1.5,0.672,1.5,1.5V35.67z M22.168,35.67c0,0.828-0.672,1.5-1.5,1.5s-1.5-0.672-1.5-1.5v-21c0-0.828,0.672-1.5,1.5-1.5   s1.5,0.672,1.5,1.5V35.67z M25.168,5.668h-9V3h9V5.668z M30.168,35.67c0,0.828-0.672,1.5-1.5,1.5s-1.5-0.672-1.5-1.5v-21   c0-0.828,0.672-1.5,1.5-1.5s1.5,0.672,1.5,1.5V35.67z"/>
                                    </g>
                                </svg>
                            </button>
                            {showingInactiveUsers ? null : <button
                                className="text-2xl sm:text-2xl !bg-blue-800 md:hover:!bg-blue-950 text-white font-bold py-2 px-2.5 rounded max-h-[60px] flex items-center"
                                onClick={() => setIsAddUserModalOpen(true)}
                            >
                                <svg xmlns="http://www.w3.org/2000/svg" className="inline mb-0.5 w-[20px] h-[20px] text-white" viewBox="0 0 24 24">
                                    <g id="user-add">
                                        <g>
                                            <path d="M17,21V19a4,4,0,0,0-4-4H5a4,4,0,0,0-4,4v2" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3"/>
                                            <circle cx="9" cy="7" fill="none" r="4" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3"/>
                                            <line stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3" x1="17" x2="23" y1="11" y2="11"/>
                                            <line stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3" x1="20" x2="20" y1="8" y2="14"/>
                                        </g>
                                    </g>
                                </svg>
                            </button>}
                        </div>
                    </>
                ) : <h2 className="text-2xl font-medium">¿Quién eres?</h2> }
            </div>
            {role && role.toLowerCase() === "superadmin" ? (
                <>
                    <AddUserModal
                        isAddUserModalOpen={isAddUserModalOpen}
                        setIsAddUserModalOpen={setIsAddUserModalOpen}
                        onAddUser={onAddUser}
                    />
                    <EditUserModal
                        selectedUser={selectedUser}
                        onEditUser={onEditUser}
                        setSelectedUser={setSelectedUser}
                    />
                    {confirmDelete()}
                </>
            ) : null }
            <div className="grid grid-cols-2 auto-rows-max gap-5 md:grid-cols-2 lg:grid-cols-2 mt-8">
                {workers.map((worker) => (
                    <div
                        key={worker.UserID}
                        className="flex flex-col"
                    >
                        {role && role.toLowerCase() === "superadmin" ? (
                            <div className="w-full bg-gray-600 flex justify-end items-center p-1.5 rounded-t rounded-b-none">
                                <svg xmlns="http://www.w3.org/2000/svg"
                                     className="h-6 w-6 text-white cursor-pointer"
                                     viewBox="0 0 24 24"
                                     fill="none"
                                     onClick={() => setSelectedUser(worker)}
                                >
                                    <path fillRule="evenodd" clipRule="evenodd" d="M8.56078 20.2501L20.5608 8.25011L15.7501 3.43945L3.75012 15.4395V20.2501H8.56078ZM15.7501 5.56077L18.4395 8.25011L16.5001 10.1895L13.8108 7.50013L15.7501 5.56077ZM12.7501 8.56079L15.4395 11.2501L7.93946 18.7501H5.25012L5.25012 16.0608L12.7501 8.56079Z" fill="currentColor"/>
                                </svg>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="h-6 w-6 text-white cursor-pointer"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                    onClick={() => setConfirmDeleteUser(worker)}
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth={2}
                                        d="M6 18L18 6M6 6l12 12"
                                    />
                                </svg>
                            </div>
                        ) : null }
                        <button
                            onClick={() => onSelectWorker(worker)}
                            className={`font-bold py-2 px-4 w-full h-full text-white ${getWorkerColor(worker.UserID)} ${role && role.toLowerCase() === "superadmin" ? 'rounded-b rounded-t-none -mt-1' : 'rounded'}`}
                        >
                            {worker.FirstName}<br/>{worker.LastName}
                        </button>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default WorkerList;