import api from './api';

export const getSetting = async (key) => {
    try {
        const response = await api.get(`/api/settings/${key}`);
        return response.data;
    } catch (error) {
        throw new Error('Error fetching setting: ' + error);
    }
};

export const postSetting = async (setting) => {
    try {
        const response = await api.post('/api/settings', setting);
        return response.data;
    } catch (error) {
        throw new Error('Error posting setting: ' + error);
    }
};

export const updateSetting = async (key, setting) => {
    try {
        const response = await api.put(`/api/settings/${key}`, setting);
        return response.data;
    } catch (error) {
        throw new Error('Error updating setting: ' + error);
    }
};

export const getSettings = async () => {
    try {
        const response = await api.get('/api/settings');
        return response.data;
    } catch (error) {
        throw new Error('Error fetching settings: ' + error);
    }
};

export const pushSettings = async (settings) => {
    try {
        const response = await api.post('/api/settings/list', settings);
        return response.data;
    } catch (error) {
        throw new Error('Error pushing settings: ' + error);
    }
};