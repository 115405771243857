import React, { useState, useEffect } from 'react';
import { getAvailableProjects, startTask } from '../../services/projectService';
import {toast} from "react-toastify";
import {getButtonColor} from "../../services/utils";

const TaskDashboard = ({ workerId, onSelectProject }) => {
    const [projects, setProjects] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const fetchProjects = async () => {
            try {
                const availableProjects = await getAvailableProjects(workerId);
                setProjects(availableProjects);
            } catch (error) {
                console.error('Error fetching projects:', error);
            }
        };

        fetchProjects();
    }, [workerId]);

    const handleProjectSelection = async (project) => {
        setLoading(true);
        await startTask(workerId, project.CodigoEmpresa, project.EjercicioAlbaran, project.SerieAlbaran, project.NumeroAlbaran)
            .then((task) => {
                onSelectProject(task);
            })
            .catch((error) => {
                toast.error(error.message);
            })
            .finally(() => {
                setLoading(false); // Add this
            });
    };

    // Group projects by 'EjercicioAlbaran'
    const projectsByYear = projects.reduce((groups, project) => {
        const year = project.EjercicioAlbaran;
        if (!groups[year]) {
            groups[year] = [];
        }
        groups[year].push(project);
        return groups;
    }, {});

    // Sort years in descending order
    const sortedYears = Object.keys(projectsByYear).sort((a, b) => b - a);

    return (
        <div>
            {sortedYears.map((year) => (
                <div key={year}>
                    <h3 className="mb-4">{year}</h3>
                    <div className="grid grid-cols-2 auto-rows-max auto-cols-max gap-5 sm:grid-cols-2 md:grid-cols-3">
                        {projectsByYear[year].map((project) => (
                            <div
                                key={project.CodigoEmpresa+project.EjercicioAlbaran+project.SerieAlbaran+project.NumeroAlbaran}
                            >
                                <button
                                    onClick={() => handleProjectSelection(project)}
                                    disabled={loading}
                                    className={`${getButtonColor(project.SerieAlbaran)} brand text-white font-bold leading-relaxed py-2 px-4 rounded w-full h-full`}
                                >
                                    {project.SerieAlbaran}-{project.NumeroAlbaran}<br />
                                    {project.RazonSocial}<br />
                                    {project.Matricula}
                                </button>
                            </div>
                        ))}
                    </div>
                </div>
            ))}
        </div>
    );
};

export default TaskDashboard;