import {useRef, useEffect} from 'react';
import {Checkbox, Label, Modal, TextInput} from "flowbite-react";

function EditUserModal({selectedUser, onEditUser, setSelectedUser}) {
    const firstNameRef = useRef(null);
    const lastNameRef = useRef(null);
    const articuloRef = useRef(null);
    const familiaRef = useRef(null);
    const plusOrgRef = useRef(null);
    const isActiveRef = useRef(null);

    const {UserID, FirstName, LastName, Articulo, Familia, PlusOrg, IsActive} = selectedUser || {};

    useEffect(() => {
        if (firstNameRef.current) firstNameRef.current.value = FirstName || '';
        if (lastNameRef.current) lastNameRef.current.value = LastName || '';
        if (articuloRef.current) articuloRef.current.value = Articulo || '';
        if (familiaRef.current) familiaRef.current.value = Familia || 'MO';
        if (plusOrgRef.current) plusOrgRef.current.checked = !!PlusOrg;
        if (isActiveRef.current) isActiveRef.current.checked = !!IsActive;
    }, [FirstName, LastName, Articulo, Familia, PlusOrg, IsActive]);

    const resetEditUserModal = () => {
        setSelectedUser(null);
        if (firstNameRef.current) firstNameRef.current.value = '';
        if (lastNameRef.current) lastNameRef.current.value = '';
        if (articuloRef.current) articuloRef.current.value = '';
        if (familiaRef.current) familiaRef.current.value = '';
        if (plusOrgRef.current) plusOrgRef.current.checked = false;
        if (isActiveRef.current) isActiveRef.current.checked = true;
    }

    return (
        <Modal show={!!selectedUser} onClose={resetEditUserModal} size="lg" position="center">
            <Modal.Header>Editando trabajador</Modal.Header>
            <Modal.Body>
                <div className="flex flex-col gap-2">
                    <Label htmlFor="firstName">
                        Nombre
                    </Label>
                    <TextInput type="text" id="firstName" className="w-full rounded-md" ref={firstNameRef}/>
                </div>
                <div className="flex flex-col gap-2 mt-4">
                    <Label htmlFor="lastName">
                        Apellidos
                    </Label>
                    <TextInput type="text" id="lastName" className="w-full rounded-md" ref={lastNameRef}/>
                </div>
                <div className="flex flex-col gap-2 mt-4">
                    <Label htmlFor="articulo">
                        Artículo
                    </Label>
                    <TextInput type="text" id="articulo" className="w-full rounded-md" ref={articuloRef}/>
                </div>
                <div className="flex flex-col gap-2 mt-4">
                    <Label htmlFor="familia">
                        Familia
                    </Label>
                    <TextInput type="text" id="familia" className="w-full rounded-md" ref={familiaRef}/>
                </div>
                <div className="flex flex-row items-center gap-2 mt-6">
                    <Label htmlFor="plusOrg">
                        ¿Plus Organizativo?
                    </Label>
                    <Checkbox id="plusOrg" className="h-6 w-6" ref={plusOrgRef}/>
                </div>
                <div className="flex flex-row items-center gap-2 mt-6">
                    <Label htmlFor="isActive">
                        ¿Activo?
                    </Label>
                    <Checkbox id="isActive" className="h-6 w-6" ref={isActiveRef}/>
                </div>
            </Modal.Body>

            <Modal.Footer>
                <div className="flex w-full items-center justify-center">
                    <button onClick={() => {
                        onEditUser(UserID, {
                            FirstName: firstNameRef.current.value,
                            LastName: lastNameRef.current.value,
                            Articulo: articuloRef.current.value,
                            Familia: familiaRef.current.value,
                            PlusOrg: plusOrgRef.current.checked,
                            IsActive: isActiveRef.current.checked
                        });

                        resetEditUserModal();
                    }}
                            className="inline-flex text-lg justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-800 text-white hover:bg-blue-900 focus:outline-none sm:w-auto lg:text-xl"
                    >
                        Confirmar cambios
                    </button>
                </div>
            </Modal.Footer>
        </Modal>
    );
}

export default EditUserModal;