import React from 'react';
import LoginForm from '../../components/LoginForm/LoginForm';

const LoginView = ({ onLogin }) => {
    return (
        <div>
            <LoginForm onLogin={onLogin} />
        </div>
    );
};

export default LoginView;