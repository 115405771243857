import {useEffect, useState} from 'react';
import {Checkbox, Label, Modal} from "flowbite-react";
import {deleteTask, getTasksByAlbaran, postTask} from "../../services/tasks2Service";
import {toast} from "react-toastify";

const AddTaskModal = ({isAddTaskModalOpen, setIsAddTaskModalOpen, project, projectTasks, setProjectTasks}) => {
    const [selectedTasks, setSelectedTasks] = useState([]);

    const taskList = [
        {
            name: 'Chasis',
            options: ['Reparar', 'Cambiar', 'Reforzar'],
            pieces: ['Soldaduras', 'Estribos', 'Mensulas', 'Placas', 'Refuerzo Interior', 'Tornilleria', 'Paragolpes Trasero', 'Paraciclistas', 'Pasos de Rueda DCH', 'Pasos de Rueda IZQ']
        },
        {
            name: 'Caballete Delantero',
            options: ['Reparar', 'Cambiar', 'Repasar'],
            pieces: ['Abarcones', 'Soldaduras', 'Reforzar', 'Cambiar']
        },
        {
            name: 'Deposito Agua',
            options: ['Reparar', 'Cambiar'],
            pieces: ['Abrazaderas', 'Tecalan', 'Cuna', 'Deposito', 'Valvulas Seguridad']
        },
        {
            name: 'Sistema de Agua',
            options: ['Reparar', 'Cambiar'],
            pieces: ['Llaves de Agua', 'Manguera Lavado', 'Manguera de Llenado', 'Tubo de Agua Tolva', 'Tubo de Agua Caballete', 'Cuernos de Agua', 'Boca de Llenado']
        },
        {
            name: 'Canaletas',
            options: ['Reparar', 'Cambiar', 'Forrar'],
            pieces: ['Canaleta Giratoria', 'Canaleta Abatible', 'Prolongacion']
        },
        {
            name: 'Caballete Trasero',
            options: ['Reparar', 'Cambiar', 'Repasar'],
            pieces: ['Abarcones', 'Soldaduras', 'Reforzar', 'Cambiar', 'Libro', 'Chasis Caballete', 'Brazos de Pantalon', 'Pantalon', 'Babero', 'Eje Canaleta', 'Puente', 'Protector']
        },
        {
            name: 'Tolva de Carga',
            options: ['Reparar', 'Cambiar', 'Forrar'],
            pieces: ['Placas', 'Soporte', 'Tornilleria', 'Puente', 'Babero']
        },
        {
            name: 'Escalera',
            options: ['Reparar', 'Cambiar'],
            pieces: ['Tramo Inferior', 'Soports Sujecion', 'Barra de Seguridad', 'Tramo Superior', 'Meseta o Descansillo']
        },
        {
            name: 'Rodillos',
            options: ['Reparar', 'Cambiar Nuevo', 'Cambiar Recuperado', 'Tornear'],
            pieces: ['Rodillo DCH', 'Rodillo IZQ', 'Soporte Rodillo DCH', 'Soporte Rodillo IZQ', 'Protector Rodillo DCH', 'Protector Rodillo IZQ']
        },
        {
            name: 'Sistema de Aire',
            options: ['Reparar', 'Cambiar'],
            pieces: ['Presurizado', 'Tecalan', 'Racores', 'Manometro']
        },
        {
            name: 'Cables Giro',
            options: ['Reparar', 'Cambiar', 'Regular'],
            pieces: ['Cable Cabina', 'Cable Trasero', 'Palanca Mando']
        },
        {
            name: 'Electricidad',
            options: ['Reparar', 'Cambiar'],
            pieces: ['Ventilador HD', 'Termostato Temperatura', 'Rele', 'Fusible T Porta Fusible', 'Caja Ciega', 'Caja de Aceleracion', 'Boton Aceleracion', 'Boton Desaceleracion', 'Pulsadores', 'Cableados', 'Pilotos Laterales']
        },
        {
            name: 'Bomba Hidraulica',
            options: ['Reparar', 'Cambiar'],
            pieces: ['Bomba Hidraulica', 'Servo Mando', 'Servo Cilindro', 'Tapas Bomba', 'Bomba de Alimentacion', 'Reten Bomba', 'Aceite y Filtro', 'Transmision', 'Mangueras']
        },
        {
            name: 'Motor',
            options: ['Reparar', 'Cambiar'],
            pieces: ['Motor', 'Taco de Valvulas', 'Manometro de Presion']
        },
        {
            name: 'Reductor',
            options: ['Reparar', 'Cambiar'],
            pieces: ['Reductor', 'Rodamiento', 'Rodamientos Planetarios', 'Reten', 'Aceite', 'Respiradero']
        },
        {
            name: 'Enfriador',
            options: ['Reparar', 'Cambiar'],
            pieces: ['Tapa Filtro', 'Filtro', 'Filtro Completo', 'Enfriador']
        },
        {
            name: 'Bombonas',
            options: ['Reparar', 'Cambiar', 'Nuevo', 'Forrar'],
            pieces: ['Bombona', 'Fondo', 'Boquilla', 'Aspas', 'Pletina', 'Paño Central', 'Cono de Fondo', 'Cono de Boquilla', 'Semi Cono', 'Aro de Rodadura', 'Tapa de Registro', 'Tochos', 'Brida de Fondo']
        },
        {
            name: 'Destajos Bombonas',
            options: ['Dest. Grande', 'Dest. Pequeño'],
            pieces: ['Aspas']
        },
        {
            name: 'Otros Trabajos',
            options: ['Reparar', 'Cambiar'],
            pieces: ['Husillo Elevacion', 'Protectores Piloto', 'Tapa Baterias', 'Cajon Extintor', 'Cuna Canaletas', 'Gancho Canaleta', 'Protector Transmision']
        }
    ];

    const [checkboxStates, setCheckboxStates] = useState({});

    useEffect(() => {
        // Create a new state object
        const initialCheckboxStates = {};
        const initialSelectedTasks = [];

        taskList.forEach(task => {
            task.pieces.forEach(piece => {
                task.options.forEach(option => {
                    const oldTaskId = `${task.name}-${piece}-${option}`;
                    const taskId = `${option}-${piece}-${task.name}`;
                    initialCheckboxStates[taskId] = !!projectTasks.some(t => t.Name === taskId) || !!projectTasks.some(t => t.Name === oldTaskId);
                    if (initialCheckboxStates[taskId]) {
                        initialSelectedTasks.push(taskId);
                    }
                });
            });
        });

        // Set the initial state
        setCheckboxStates(initialCheckboxStates);
        setSelectedTasks(initialSelectedTasks);
    }, [project, projectTasks]); // Empty dependency array ensures this runs only on the first render

    const handleTaskChange = (event) => {
        const {id, checked} = event.target;

        setCheckboxStates(prevStates => ({...prevStates, [id]: checked}));

        setSelectedTasks(prevTasks => {
            if (checked) {
                return [...prevTasks, id];
            } else {
                return prevTasks.filter(task => task !== id);
            }
        });
    };

    const handleAddTasks = async () => {
        try {
            // Sequentially delete tasks that are not in selectedTasks
            for (const task of projectTasks) {
                if (!selectedTasks.includes(task.Name)) {
                    await deleteTask(task.TaskID);
                }
            }

            // Iterate over the selectedTasks array
            for (const taskName of selectedTasks) {
                // If a task in selectedTasks is not in projectTasks, add it
                if (!projectTasks.some(t => t.Name === taskName)) {
                    const newTask = {
                        CodigoEmpresa: project.CodigoEmpresa,
                        EjercicioAlbaran: project.EjercicioAlbaran,
                        SerieAlbaran: project.SerieAlbaran,
                        NumeroAlbaran: project.NumeroAlbaran,
                        Enabled: true,
                        Name: taskName,
                        CreatedDate: new Date(),
                        UpdatedDate: null
                    };

                    // Await each postTask promise one by one
                    await postTask(newTask);
                }
            }

            // Fetch the updated tasks from the database
            const updatedTasks = await getTasksByAlbaran(project.CodigoEmpresa, project.EjercicioAlbaran, project.SerieAlbaran, project.NumeroAlbaran);

            // Update the projectTasks state in the parent component
            setProjectTasks(updatedTasks);

            // Close the modal
            setIsAddTaskModalOpen(false);
        } catch (error) {
            console.error('Error adding tasks: ', error);
            toast.error('Error al añadir o eliminar las tareas (es probable que algún trabajador haya trabajado ya en la tarea).');

            // Close the modal
            setIsAddTaskModalOpen(false);
        }
    };

    const renderCheckboxGroup = (task) => (
        <div key={task.name} className="my-4">
            <h3 className="font-semibold mb-3">{task.name}:</h3>
            <table className="min-w-full divide-y divide-gray-200">
                <thead>
                <tr>
                    <th className="text-left">Trabajo a Realizar</th>
                    {task.options.map(option => (
                        <th key={option} className="text-center">{option}</th>
                    ))}
                </tr>
                </thead>
                <tbody>
                {task.pieces.map(piece => (
                    <tr key={piece}>
                        <td className="text-left">{piece}</td>
                        {task.options.map(option => {
                            const taskId = `${option}-${piece}-${task.name}`;
                            return (
                                <td key={option} className="text-center">
                                    <Checkbox id={taskId} checked={checkboxStates[taskId]} onChange={handleTaskChange}/>
                                </td>
                            );
                        })}
                    </tr>
                ))}
                </tbody>
            </table>
        </div>
    );

    return (
        <Modal show={isAddTaskModalOpen} onClose={() => setIsAddTaskModalOpen(false)} size="5xl" position="center">
            <Modal.Header>Definición de la Avería</Modal.Header>
            <Modal.Body>
                <div className="flex flex-col sm:grid md:grid-cols-2 gap-4 mt-2">
                    {taskList.map(task => renderCheckboxGroup(task))}
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button onClick={() => handleAddTasks()}
                        className="inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-white hover:bg-blue-700 focus:outline-none sm:w-auto">
                    Confirmar
                </button>
            </Modal.Footer>
        </Modal>
    );
};

export default AddTaskModal;