import React, {useState, useEffect, useContext, useRef} from 'react';
import {Modal, TextInput, Button, Label, Select, Checkbox} from "flowbite-react";
import {toast} from "react-toastify";
import {getUsers} from "../../services/userService";
import {WorkersContext} from '../../App';
import {getTasksByAlbaran} from "../../services/tasks2Service";

// Assume workers is an array of worker objects you pass to this modal
// Each worker object could look like: { id: 1, name: "John Doe" }
const AddTimeEntryModal = ({isOpen, onClose, task, onSave}) => {
    // Define a dictionary of options for each SerieAlbaran
    const optionsDict = {
        "T": [
            'SELECCIONA UNA OPCIÓN:',
            'MANTENIMIENTO TALLER',
            'DESCARGA MERCANCÍA',
            'SALIDA MATERIAL',
            'SALIDA DIRECCIÓN',
            'USO FURGONETA',
            'SERVICIO ALMACÉN',
        ],
        // Add more SerieAlbaran types with their own options
        // "S": ['option1', 'option2'],
    };

    const [workers, setWorkers] = useContext(WorkersContext);
    const [startTime, setStartTime] = useState('');
    const [endTime, setEndTime] = useState('');
    const [selectedWorker, setSelectedWorker] = useState(0);
    const [selectedOption, setSelectedOption] = useState(optionsDict[task.SerieAlbaran] ? optionsDict[task.SerieAlbaran][0] : '');
    const [projectTasks, setProjectTasks] = useState([]);
    const [selectedProjectTasks, setSelectedProjectTasks] = useState([]);

    useEffect(() => {
        if (task && isOpen) {
            // Fetch project tasks when the task is set
            const fetchProjectTasks = async () => {
                const tasks = await getTasksByAlbaran(task.CodigoEmpresa, task.EjercicioAlbaran, task.SerieAlbaran, task.NumeroAlbaran);
                setProjectTasks(tasks);
            };

            fetchProjectTasks();
        }
    }, [task, isOpen]);

    useEffect(() => {
        // Fetch workers when the modal is opened
        const fetchWorkers = async () => {
            const workers = await getUsers();
            setWorkers(workers);
        };

        if (isOpen) {
            //cleanup
            setSelectedWorker(0);
            setStartTime('');
            setEndTime('');
            setSelectedOption(optionsDict[task.SerieAlbaran] ? optionsDict[task.SerieAlbaran][0] : '');
            setSelectedProjectTasks([]);

            fetchWorkers();

            console.log('workers', workers);
        }
    }, [isOpen]);

    const handleSave = async () => {
        // Perform validation or any additional logic before saving
        if (!startTime || !endTime || !selectedWorker || selectedWorker === 0) {
            toast.error("Por favor añade trabajador y fecha y hora de inicio.");
            return;
        }

        const defaultOption = optionsDict[task.SerieAlbaran] ? optionsDict[task.SerieAlbaran][0] : '';

        let comment;

        let subTimeEntries = [];

        if (projectTasks && projectTasks.length > 0) {
            if (selectedProjectTasks.length === 0) {
                toast.error('Por favor, selecciona un comentario.');
                return;
            }
            for (const taskId of selectedProjectTasks) {
                subTimeEntries.push(taskId);
            }
        }
        // Check if optionsDict contains the SerieAlbaran and if the selected option is not the default one
        else if (optionsDict[task.SerieAlbaran] && selectedOption !== '' && selectedOption !== defaultOption) {
            comment = selectedOption;
        } else if (optionsDict[task.SerieAlbaran] && (selectedOption === '' || selectedOption === defaultOption)) {
            // If there are options but the selected one is the default, cancel the action and tell him to select a comment
            toast.error('Por favor, selecciona un comentario.');
            return;
        }

        onSave({
            UserID: selectedWorker,
            StartTime: startTime,
            EndTime: endTime,
            WorkerComment: comment,
        }, subTimeEntries);

        onClose(); // Close the modal
    };

    const handleTaskChange = (event) => {
        const {id, checked} = event.target;
        setSelectedProjectTasks(prevTasks => {
            if (checked) {
                return [...prevTasks, id];
            } else {
                return prevTasks.filter(task => task !== id);
            }
        });
    };

    const flattenOptions = (options) => {
        const flattened = [];

        for (let key in options) {
            const value = options[key];

            // If the value is an array, prefix each item with the key
            if (Array.isArray(value)) {
                value.forEach((item) => {
                    flattened.push(`${key}: ${item}`);
                });
            }
            // If the value is an object, recursively flatten it
            else if (typeof value === 'object') {
                const nestedOptions = flattenOptions(value);

                nestedOptions.forEach((opt) => {
                    flattened.push(`${key}: ${opt}`);
                });
            }
            // Otherwise, the value is a simple string - just add it directly
            else {
                flattened.push(value);
            }
        }

        return flattened;
    };

    return (
        <Modal show={isOpen} onClose={onClose} size="lg" position="center">
            <Modal.Header>
                {task ? "Edit Task" : "Add New Task"}
            </Modal.Header>
            <Modal.Body>
                <div className="flex flex-col gap-4">
                    <Label htmlFor="workerSelect" className="text-base">Trabajador:</Label>
                    <Select id="workerSelect"
                            value={selectedWorker}
                            onChange={(e) => setSelectedWorker(e.target.value)}
                    >
                        <option value="">Selecciona un trabajador</option>
                        {workers.map(worker => (
                            <option key={worker.UserID}
                                    value={worker.UserID}
                                    id={worker.UserID}
                            >
                                {worker.FirstName} {worker.LastName}
                            </option>
                        ))}
                    </Select>
                    <Label htmlFor="startTime" className="text-base mt-2">Fecha y hora de inicio:</Label>
                    <TextInput id="startTime" type="datetime-local" value={startTime}
                               onChange={(e) => setStartTime(e.target.value)} placeholder="Fecha Inicio"/>
                    <Label htmlFor="endTime" className="text-base mt-2">Fecha y hora de fin:</Label>
                    <TextInput id="endTime" type="datetime-local" value={endTime}
                               onChange={(e) => setEndTime(e.target.value)}
                               placeholder="Fecha Fin"/>
                    {projectTasks && projectTasks.length > 0 && (
                        <div>
                            <Label className="text-base mt-2 mb-4">Tareas realizadas:</Label>
                            {projectTasks.map(ptask => (
                                <div key={ptask.Name} className="flex text-base items-center gap-2 mt-1 mb-1">
                                    <Checkbox className="w-6 h-6" id={ptask.TaskID}
                                              checked={selectedProjectTasks.includes(ptask.TaskID.toString())}
                                              onChange={handleTaskChange}/>
                                    <Label className="text-base" htmlFor={ptask.TaskID}>{ptask.Name}</Label>
                                </div>
                            ))}
                        </div>
                    )}
                    {optionsDict[task.SerieAlbaran] && (
                        <div>
                            <p className="text-base mt-3 mb-2">Comentario:</p>
                            <select
                                className="text-md sm:text-lg text-black !bg-gray-100 rounded-3xl py-1.5 px-3 max-w-full whitespace-normal"
                                value={selectedOption} onChange={e => setSelectedOption(e.target.value)}>
                                {flattenOptions(optionsDict[task.SerieAlbaran]).map(option => (
                                    <option className="text-md sm:text-lg max-w-[100vw] whitespace-normal"
                                            value={option}
                                            key={option}>
                                        {option}
                                    </option>
                                ))}
                            </select>
                        </div>
                    )}
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={handleSave}>
                    Guardar
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default AddTimeEntryModal;