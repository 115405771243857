import api from './api';

export const getSubTimeEntry = async (id) => {
    try {
        const response = await api.get(`/api/subtimeentries/${id}`);
        return response.data;
    } catch (error) {
        throw new Error('Error fetching sub time entry: ' + error);
    }
};

export const getTasksByTimeEntryID = async (id) => {
    try {
        const response = await api.get(`/api/subtimeentries/timeentry/${id}`);
        return response.data;
    } catch (error) {
        throw new Error('Error fetching tasks by time entry ID: ' + error);
    }
};

export const getTimeEntryByTaskID = async (id) => {
    try {
        const response = await api.get(`/api/subtimeentries/task/${id}`);
        return response.data;
    } catch (error) {
        throw new Error('Error fetching time entry by task ID: ' + error);
    }
};

export const postSubTimeEntry = async (subTimeEntry) => {
    try {
        const response = await api.post('/api/subtimeentries', subTimeEntry);
        return response.data;
    } catch (error) {
        throw new Error('Error posting sub time entry: ' + error);
    }
};

export const deleteSubTimeEntry = async (timeEntryId, taskId) => {
    try {
        const response = await api.delete(`/api/subtimeentries/${timeEntryId}/${taskId}`);
        return response.data;
    } catch (error) {
        throw new Error('Error deleting sub time entry: ' + error);
    }
};

export const deleteSubTimeEntriesByTimeEntryID = async (id) => {
    try {
        const response = await api.delete(`/api/subtimeentries/timeentry/${id}`);
        return response.data;
    } catch (error) {
        throw new Error('Error deleting sub time entries by time entry ID: ' + error);
    }
};

export const deleteSubTimeEntriesByTaskID = async (id) => {
    try {
        const response = await api.delete(`/api/subtimeentries/task/${id}`);
        return response.data;
    } catch (error) {
        throw new Error('Error deleting sub time entries by task ID: ' + error);
    }
};